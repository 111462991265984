import { Component, OnInit } from '@angular/core';
import { AdminService } from '../services/admin.service';

declare var $ : any;

@Component({
	selector: 'app-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.css']
})

export class NavbarComponent implements OnInit {
	toolTipPosition = "before"
	allCategories: any;
	
	constructor(private _admin: AdminService) {
		this._admin.readCategories().subscribe(data => {
				this.allCategories = data
		});
	}
	ngOnInit(): void{}
}
