import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { GlobalConstants } from "../common/global-constants";

@Injectable({
	providedIn: 'root'
})
export class AdminService {
	
	categoriesPath: string = "assets/products/";
	filesPath: string = "assets/products/product-list/";

	CREDENTIALSFILE = "assets/files/credentials.json";

	constructor(private _http: HttpClient) { }
	
	basePath = GlobalConstants.apiURL;

	private _verifyCredentials = this.basePath + "_verifyCredentials"
	private _verifyPassword = this.basePath + "_verifyPassword"
	private _addCategory = this.basePath + "addCategory"
	private _deleteCategory = this.basePath + "deleteCategory"
	private _addProduct = this.basePath + "addProduct"
	private _modifyProduct = this.basePath + "modifyProduct"
	private _deleteProduct = this.basePath + "deleteProduct"

	private _contactQueryEmail = this.basePath + "changeContactQueryEmail"


	public readCategories(): Observable<any> {
		return this._http.get(this.categoriesPath + "categories.json");
	}
	
	readFile(fileName): Observable<any> {
		return this._http.get(this.filesPath + fileName + ".json");
	}

	readFileLength(fileName): Observable<any> {
		return this._http.get(this.filesPath + fileName);
	}

	addCategory(data): any {
		return this._http.post<any>(this._addCategory, data);
	}

	deleteCategory(data): any {
		return this._http.post<any>(this._deleteCategory, data);
	}

	addProduct(data): any {
		return this._http.post<any>(this._addProduct, data);
	}
	
	modifyProduct(data): any {
		return this._http.post<any>(this._modifyProduct, data);
	}

	deleteProduct(data): any {
		return this._http.post<any>(this._deleteProduct, data);
	}

	verifyCredentials(email, password): any {
		return this._http.post<any>(this._verifyCredentials, {
			"email": email,
			"password": password
		});
	}

	verifyPassword(password): any {
		return this._http.post<any>(this._verifyPassword, {
			"password": password
		});
	}
	
	readCredentials(): Observable<any> {
		return this._http.get<any>(this.CREDENTIALSFILE);
	}

	getContactQueryEmail(): any {
		return this._http.get<any>(this.CREDENTIALSFILE);
	}

	changeContactQueryEmail(data): any {
		return this._http.post<any>(this._contactQueryEmail, data);
	}
}
