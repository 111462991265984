import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from "@angular/platform-browser";

import { IpService } from "../services/ip.service";

import { Container, Main } from 'ng-particles';

import { NgImageSliderComponent } from 'ng-image-slider';
@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {

	img_path = "assets/images/";
	carousel_path = `${this.img_path}home_carousel`
	home_carousel_images = 10;

	landing = [
		{ path: this.img_path + 'bg-hm-4.jpg' },
		{ path: this.img_path + 'bg-hm-2.jpg' },
		{ path: this.img_path + 'bg-hm-3.jpg' },
	];

	carousel_images = [
		"A-Vent Drops",
		"Aarcal-D3 Drops",
		"Aarcin 500 Injection",
		"Azole-Plus Suspension",
		"Dilmox-CV 375",
		// "Emix-50 DT",
		"Folcidel",
		// "Line-D -min",
		// "Maxi-DL-min",
		"Melody",
		// "Nastroz",
		"Prime Dry Syrup",
		// "Recal-MZ",
		// "SFD",
		"Sure Drops",
		"Velvet Drops",
		// "Vitacare Plus",
		// "Vitacare",
		"X-100 XT"
	]
	products_slides: Array<object> = []

	imageSizeOptions = {
		width: '450',
		height: '300',
		space: 3
	}
	autoSlideOptions = {
		interval: 3,
		stopOnHover: true
	}

	ngOnInit(): void {
		for (let i = 1; i <= this.home_carousel_images; i++) {
			var obj = {
				image: `${this.carousel_path}/${this.carousel_images[i]}.JPG`,
				thumbImage: `${this.carousel_path}/${this.carousel_images[i]}.JPG`,
				title: `${this.carousel_images[i]}`,
				alt: `${this.carousel_images[i]}`
			}
			this.products_slides.push(obj)	
		}

		console.log(this.products_slides)
	}

	public constructor(private title: Title, private _ip: IpService) {
		this.title.setTitle("Aarsh Remedies Pvt. Ltd.");
	}

	background_mask = {
		"background": {
			"color": {
				"value": "#ffffff"
			},
			"image": "url('assets/images/background.jpg')",
			"position": "50% 50%",
			"repeat": "no-repeat",
			"size": "cover"
		},
		"backgroundMask": {
			"cover": {
				"color": {
					"value": {
						"r": 255,
						"g": 255,
						"b": 255
					}
				}
			},
			"enable": true
		},
		"fullScreen": {
			"enable": false,
			"zIndex": 1
		},
		"interactivity": {
			"events": {
				"onClick": {
					"enable": false,
					"mode": "push"
				},
				"onHover": {
					"enable": true,
					"mode": "grab",
					"parallax": {
						"enable": true,
						"force": 60
					}
				}
			},
			"modes": {
				"bubble": {
					"distance": 400,
					"duration": 2,
					"opacity": 1,
					"size": 100
				},
				"grab": {
					"distance": 150
				},
				"repulse": {
					"distance":	200,
					"duration":	0.4,
					"speed": 1
				}
			}
		},
		"particles": {
			"color": {
				"value": "#ffffff"
			},
			"links": {
				"color": {
					"value": "#ffffff"
				},
				"distance": 150,
				"enable": true
			},
			"move": {
				"attract": {
					"rotate": {
						"x": 600,
						"y": 1200
					}
				},
				"enable": true,
				"outModes": {
					"bottom": "out",
					"left": "out",
					"right": "out",
					"top": "out"
				}
			},
			"number": {
				"density": {
					"enable": true
				},
				"value": 100
			},
			"opacity": {
				"animation": {
					"speed": 1,
					"minimumValue": 0.1
				}
			},
			"size": {
				"random": {
					"enable": true
				},
				"value": 15,
				"animation": {
					"speed": 40,
					"minimumValue": 0.1
				}
			}
		}
	}

	particlesLoaded(container: Container): void {}

	particlesInit(main: Main): void {}

	@ViewChild('nav') slider: NgImageSliderComponent;

	prevImageClick() {
		this.slider.prev();
	}

	nextImageClick() {
		this.slider.next();
	}
}
