const PORT = 3000

export class GlobalConstants {

	public static apiURL: string = `https://aarsh-remedies-api.web.app/`;

	public static homeTitle: string = "Aarsh Remedies Pvt. Ltd.";
	public static siteTitle: string = ` | ${GlobalConstants.homeTitle}`;
	public static siteURL: string = "https://aarshremedies.com"

	public static primary_email: string = "aarshremedies@gmail.com"
	public static secondary_email: string = "brijsheth@aarshremedies.com"
	
	public static assetsPath: string = "assets";
}
