import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatSort } from '@angular/material/sort';

import { ReadJSONService } from "../services/read-json.service";
import { AdminService } from '../services/admin.service';
import { AuthService } from '../services/auth.service';
import { take } from 'rxjs/operators';


@Component({
	selector: 'app-admin-dashboard',
	templateUrl: './admin-dashboard.component.html',
	styleUrls: ['./admin-dashboard.component.css']
})

export class AdminDashboardComponent implements OnInit {
	
	no_of_products: number[] = [];
	dataSource: any;
	displayedColumns: string[] = ["no", "displayName", "no-of-products", "actions", "launch"];
	res: any;
	allCategories: any;
	totalProducts: number = 0;
	
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	constructor(private router: Router, private title : Title, private snackbar: MatSnackBar, private _auth: AuthService, public _admin: AdminService, public dialog: MatDialog) {
		
		this.title.setTitle("Admin Dashboard | Aarsh Remedies Pvt. Ltd.");

		this._auth.adminLogin().subscribe(
			res => {},
			err => {
				localStorage.removeItem("key")
				this.router.navigate(['/products/categories/aarsh/remedies/pvt/ltd'])
			}
		)

		this.res = this._admin.readCategories().pipe(take(1)).subscribe(
			data => {
				for(let i = 0; i < data.length; i++) {
					this._admin.readFileLength(data[i].fileName).subscribe(res => {
						this.totalProducts += res["products"].length;
						this.no_of_products.push(res["products"].length)
					})
				}
				this.allCategories = data;
				
				this.dataSource = new MatTableDataSource(data);
				
				/* For Sorting in table */
				this.dataSource.sort = this.sort;
			}
				
		)
	}

	ngOnInit(): void {}

	addCategory(){
		// For Dialog Configuration
		const dialogConfig = new MatDialogConfig();

		dialogConfig.autoFocus = true;
		dialogConfig.data = {}

		const dialogCategory = this.dialog.open(AddCategoryComponent, dialogConfig)

	}
	
	modifyCategory(event) {
		let target = event.target.attributes.id || event.srcElement.attributes.id || event.currentTarget.attributes.id;
		let value = target.nodeValue;
		
		// For Dialog Configuration
		const dialogConfig = new MatDialogConfig();

		dialogConfig.autoFocus = true;
		dialogConfig.data = {
			// details: this.fileData[value - 1],
			// category: this.slug,
			// id: value
		}

		// const dialogProduct = this.dialog.open(ModifyCategoryComponent, dialogConfig)
	}

	deleteCategory(event) {
		let target = event.target.attributes.id || event.srcElement.attributes.id || event.currentTarget.attributes.id;
		let value = target.nodeValue;

		// For Dialog Configuration
		const dialogConfig = new MatDialogConfig();

		dialogConfig.autoFocus = true;
		dialogConfig.data = {
			category: this.allCategories[value - 1].name,
			name: this.allCategories[value - 1].displayName,
			id: value - 1
		}

		const dialogProduct = this.dialog.open(DeleteCategoryComponent, dialogConfig)
	}

	// addProduct(){
	// 	// For Dialog Configuration
	// 	const dialogConfig = new MatDialogConfig();

	// 	dialogConfig.autoFocus = true;
	// 	dialogConfig.data = {
	// 		"allCategories": this.allCategories
	// 	}

	// 	const dialogProduct = this.dialog.open(AddProductComponent, dialogConfig)
		
	// }

	logout() {
		localStorage.removeItem("key")
		let snackBarRef = this.snackbar.open('Successfully Logged Out', 'Log In', {
			duration: 3500
		});

		this.router.navigate(['/'])

		snackBarRef.onAction().subscribe(() => {
			this.router.navigate(['/products/categories/aarsh/remedies/pvt/ltd'])
		})
	}
}


/* AddCategory Component */
@Component({
	selector: 'add-category',
	templateUrl: './addCategory.html',
	styleUrls: ['./addCategory.css']
})

export class AddCategoryComponent implements OnInit {
	
	isValid: boolean = false

	form: FormGroup

	constructor(private fb: FormBuilder, public dialogRef: MatDialogRef<AddCategoryComponent>, private snackbar: MatSnackBar, public _admin: AdminService){ }
	
	ngOnInit(): void {
		this.form = this.fb.group({
			categoryName: '',
			displayName: '',
			description: '',
			// fileName: ''
		})
	}

	onImageUploadClicked(event){
		

	}
	
	onUploadClicked($event) {

	}
	
	onSelectedFilesChanged($event) {
		
	}

	onSubmit() {
		var data = {
			"categoryName": this.form.value.categoryName,
			"displayName": this.form.value.displayName,
			"description": this.form.value.description,
			// "fileName": this.form.value.fileName,
		}
		
		this._admin.addCategory(data).pipe(take(1)).subscribe(
			err => {
				this.dialogRef.close();
				this.snackbar.open("Add Category Cancelled", "Dismiss", {
					duration: 3500,
				});
			},
			res => {
				this.dialogRef.close();
				var msg = ""
				
				if(res.status === 204) {
					msg = 'Data Empty, not modified'
				}
				if(res.status === 304) {
					msg = "Couldn't read file. Please try again"
					
				}
				if(res.status === 201) {
					msg = 'Category Added Successfully'
				}
				
				this.snackbar.open(msg, "Dismiss", {
					duration: 3500,
				});
			}
		)
	}
	
	check() {
		this.isValid = false
		
		if(this.form.value.categoryName === this.form.value.fileName) {
			this.isValid = true
		}
	}

	close() {
		this.snackbar.open("Add Category Cancelled", "Dismiss", {
			duration: 3500,
		});
		this.dialogRef.close();
	}
}


/* ModifyCategory Component */
@Component({
	selector: 'modify-product',
	templateUrl: './modifyCategory.html',
	styleUrls: ['./modifyCategory.css']
})

export class ModifyCategoryComponent implements OnInit {
	/*
	form: FormGroup

	category: string;
	productName: string
	productFormulation: string
	packing: string
	id: number

	constructor(@Inject(MAT_DIALOG_DATA) public data: ModifyDialogData, private fb: FormBuilder, public dialogRef: MatDialogRef<ModifyCategoryComponent>, private _admin: AdminService, private snackbar: MatSnackBar){
		this.category = data.category
		this.productName = data.details.	product_name
		this.productFormulation = data.details.product_formulation
		this.packing = data.details.packing
		this.id = data.id
	}
*/
	
	ngOnInit(): void {
		// this.form = this.fb.group({
		// 	productCategory : this.category,
		// 	productName: this.productName,
		// 	productFormulation: this.productFormulation,
		// 	packing: this.packing
		// })
	}
	/*
	onSubmit() {

		if(this.productName === this.form.value.productName && this.productFormulation === this.form.value.productFormulation && this.packing === this.form.value.packing) {
			this.dialogRef.close();
			this.snackbar.open("No changes made", "Dismiss", {
				duration: 3500
			})
		}
		else {
			var data = {
				"productCategory" : this.category,
				"productName": this.form.value.productName,
				"productFormulation": this.form.value.productFormulation,
				"packing": this.form.value.packing,
				"id": this.id
			}
			
			this._admin.modifyProduct(data).pipe(take(1)).subscribe(
				err => {
					this.dialogRef.close();
					this.snackbar.open("Modify Product Cancelled", "Dismiss", {
						duration: 3500,
					});
				},
				res => {
					console.log("In res");
					
					this.dialogRef.close();
					var msg = ""
					
					if(res.status === 204) {
						msg = 'Data Empty, not modified'
					}
					if(res.status === 304) {
						msg = "Couldn't read file. Please try again"
						
					}
					if(res.status === 202) {
						msg = 'Product Modified Successfully'
					}
					
					this.snackbar.open(msg, "Dismiss", {
						duration: 3500,
					});
				}
			)
			
		}
	}

	close() {
		this.dialogRef.close();
	}
	*/
}


/* DeleteCategory Component */
@Component({
	selector: 'delete-category',
	templateUrl: './deleteCategory.html',
	styleUrls: ['./deleteCategory.css']
})

export class DeleteCategoryComponent implements OnInit {
	
	category: string
	name: string
	id: number

	constructor(@Inject(MAT_DIALOG_DATA) public data: DeleteDialogData, public dialogRef: MatDialogRef<DeleteCategoryComponent>, private _admin: AdminService, private snackbar: MatSnackBar){
		this.category = data.category;
		this.name = data.name;
		this.id = data.id;
	}
	
	ngOnInit(): void {}
	
	onSubmit(event) {
		let target = event.target.attributes.id || event.srcElement.attributes.id || event.currentTarget.attributes.id;
		let value = target.nodeValue;
		
		if(value === 'delete') {
			var data = {
				productCategory : this.category,
				name: this.name,
				id: this.id
			}
			
			this._admin.deleteCategory(data).pipe(take(1)).subscribe(
				err => {
					this.dialogRef.close();
					this.snackbar.open("Delete Category Cancelled", "Dismiss", {
						duration: 3500,
					});
				},
				res => {
					console.log("In res");
					
					this.dialogRef.close();
					var msg = ""
					
					if(res.status === 204) {
						msg = 'Data Empty, not modified'
					}
					if(res.status === 304) {
						msg = "Couldn't read file. Please try again"
					}
					if(res.status === 202) {
						msg = 'Category Deleted Successfully'
					}
					
					this.snackbar.open(msg, "Dismiss", {
						duration: 3500,
					});
				}
			)
		}
	}

	close(event) {
		this.dialogRef.close();
	}
}

export interface DialogData {
	catName: string,
	catValue: string
}


export interface ModifyDialogData {
	category: string
	id: number
}

export interface DeleteDialogData {
	category: string
	name: string
	id: number
}