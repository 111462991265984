<nav class="navigation mat-elevation-z8" id="main-navbar">
	<mat-toolbar>
		<mat-toolbar-row id="main-info-bar" fxLayoutAlign="space-between center">
			<div>
				<a [routerLink]="['/']" routerLinkActive="router-link-active">
					<img src="assets/files/logo.png" alt="Aarsh Remedies" class="img">
				</a>
			</div>
			<div fxHide.lt-md>
				<span>
					<button class="fonts" routerLink="/" mat-button>Home</button>
				</span>
				<span>
					<button class="fonts" routerLink="/about-us" mat-button>About Us</button>
				</span>
				<span style="height: 50px;">
					<button class="fonts" routerLink="/products" mat-button>Products</button>
			</span>
			<span>
				<button class="fonts" routerLink="/contact-us" mat-button>Contact Us</button>
			</span>
			</div>
			<div class="navmenu" fxHide.gt-sm>
				<span>
					<button class="fonts" mat-button [matMenuTriggerFor]="navmenu" #menuTrigger="matMenuTrigger">
						<mat-icon>menu</mat-icon>
					</button>
				</span>
			</div>
		</mat-toolbar-row>
	</mat-toolbar>
</nav>
<br><br><br><br>

<mat-menu #navmenu="matMenu" [overlapTrigger]="false" xPosition="before">
	<button class="fonts" routerLink="/" mat-menu-item>
		<mat-icon>home</mat-icon>
		Home
	</button>
	<button class="fonts" routerLink="/about-us" mat-menu-item>
		<mat-icon>info</mat-icon>
		About
	</button>
	<button class="fonts" routerLink="/products" mat-menu-item>
		<!-- <mat-icon svgIcon="tablet"></mat-icon> -->
		<mat-icon>local_pharmacy</mat-icon>
		Products
	</button>
	<button class="fonts" routerLink="/contact-us" mat-menu-item>
		<mat-icon>phone</mat-icon>
		Contact Us
	</button>
</mat-menu>
