import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})

export class ReadJSONService {

	filePath: string = "assets/products/product-list/";
	credentialsPath: string = "assets/files/";

	countryCodes:string = "assets/js/country_codes.json";

	constructor(private httpClient: HttpClient){}
	
	public getJSON(fileName): Observable<any>
	{
		return this.httpClient.get(this.filePath + fileName + ".json");
	}

	public readCredentials(): Observable<any>
	{
		return this.httpClient.get(this.credentialsPath + "credentials.json");
	}

	readCountryCodes(): Observable<any> {
		return this.httpClient.get(this.countryCodes);
	}

	
}
