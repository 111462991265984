<form [formGroup]="form" (ngSubmit)="onSubmit()">
	<div style="min-width: 350px; min-height: 310px;">
		<h2 mat-dialog-title>Modify Product</h2>
		<mat-dialog-content>
			<mat-form-field>
				<mat-label>Product Name</mat-label>
				<input matInput formControlName="productName" required #name>
				<mat-hint align="end"><b>{{ name.value.length }}</b></mat-hint>
			</mat-form-field>
			<br>
			<mat-form-field>
				<mat-label>Product Formulation</mat-label>
				<input matInput formControlName="productFormulation" required #formulation>
				<mat-hint align="end"><b>{{ formulation.value.length }}</b></mat-hint>
			</mat-form-field>
			<br>
			<mat-form-field>
				<mat-label>Packing</mat-label>
				<input matInput formControlName="packing" required>
			</mat-form-field>
			<br>
			<!-- 
			<button mat-icon-button (click)="to_upper()" matTooltip="To UPPER Case">
			<button mat-icon-button (click)="formulation.value.toUpperCase()" matTooltip="Convert To UPPER Case">
				<mat-icon>arrow_circle_up</mat-icon>
			</button>
			<button mat-icon-button (click)="to_lower()" matTooltip="Convert To lower case">
			<button mat-icon-button (click)="formulation.value.toLowerCase()" matTooltip="Convert To lower case">
				<mat-icon>arrow_circle_down</mat-icon>
			</button>
			<button mat-icon-button (click)="to_title()" matTooltip="Convert To Title Case">
				<mat-icon>text_fields</mat-icon>
			</button>
			-->
		</mat-dialog-content>

		<mat-dialog-actions align="end">
			<button mat-raised-button color="warn" (click)="close()">Cancel</button>
			<button mat-raised-button color="primary" type="submit">Modify</button>
		</mat-dialog-actions>
	</div>
</form>