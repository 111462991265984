import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { TimelineItem } from 'ngx-timeline-albe';

@Component({
	selector: 'app-about',
	templateUrl: './about.component.html',
	styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

	data: Array<TimelineItem> | String;

	public constructor(private title : Title) {
		this.title.setTitle("About Us | Aarsh Remedies Pvt. Ltd.");
	}

	ngOnInit(): void {
		this.data = `[
			{
				"datetime": "2002-05-01 00:00:00",
				"body": [
					{
						"tag": "h1",
						"content": "Dial Pharmaceuticals Pvt. Ltd."
					},
					{
						"tag": "p",
						"content": "Late Mr. Jamnadas Sheth, a visionary, philanthropist and dedicated leader in Pharmaceutical industry, laid the foundation of Dial Group in 2002. Dial Group inaugurated its first Pharmaceutical industry in the city of Ahmedabad. Ahmedabad is also known as ‘The Pharma Hub of India’"
					}
				]
			},
			{
				"datetime": "2005-06-01 00:00:00",
				"body": [
					{
						"tag": "h1",
						"content": "Aarsh Remedies Pvt. Ltd."
					},
					{
						"tag": "p",
						"content": "Dial Group receives GMP and ISO certifications of manufacturing units in Vatva GIDC, Ahmedabad. Mr. Ajay Sheth incorporated Dial Pharmaceuticals Pvt. Ltd. and started expanding the company’s product portfolio of 500+ products ranging from Tablets, Capsules, Ointments, Creams, Syrups and Suspensions"
					}
					]
			},
			{
				"datetime": "2012-03-29 23:59:59",
				"body": [
					{
						"tag": "h1",
						"content": "Acquired 3 plants"
					},
					{
						"tag": "p",
						"content": "After having vast experience in Manufacturing, Mr. Ajay Sheth ventured into Ethical Marketing and Aarsh Remedies Pvt. Ltd. was born. Then, unveiled a new logo and corporate identity in Ahmedabad. Acquired 3 manufacturing plants and diversified production of Tablets, Capsules (Beta Lactum and Non-Beta Lactum), Ointments, Creams, Syrups, Dry Syrups and Suspensions."
					}
				]
			},
			{
				"datetime": "2014-08-15 23:59:59",
				"body": [
					{
						"tag": "h1",
						"content": "Export"
					},
					{
						"tag": "p",
						"content": "Ventured into Exports. Started exporting finished formulations in Middle – East, Latin America, Europe and Asia, totaling of 20 countries. Partnered with Manufacturing plants with EU-GMP, PIC/S, UK-MHRA, TGA, GCC, GLP UAE certificates."
					}
				]
			},
			{
				"datetime": "2021-04-01 23:59:59",
				"body": [
					{
						"tag": "h1",
						"content": "Current State"
					},
					{
						"tag": "p",
						"content": "Today, more than 1500 doctors trust Aarsh Remedies Pvt. Ltd. for high quality products. Aarsh Remedies Pvt. Ltd. has reached heights of success because of it’s highly skilled sales team and because of a VISIONARY Leader who have always worked towards the betterment of health industry."
					}
				]
			}
		]`;
	}

	tiles = [
		{
			header: 'Mission Statement',
			text: 'To its unparalleled service and unsurpassed quality, Aarsh Remedies has got all that it takes to grow its commitment to excellence.',
			cols: 5,
			rows: 3
		},
		{
			image: "assets/images/mission_statement.jpg",
			cols: 3,
			rows: 3
		},
		{
			image: "assets/images/vision_statement.jpg",
			cols: 3,
			rows: 3
		},
		{
			header: 'Vision Statement',
			text: `
"A vision that unleashes VALUE".
Aarsh Remedies shall be a leading global health provider with a robust product pipeline. Opening new pathways through innovation and quality excellence, we shall be the leading manufacturer in the nation.`,
			cols: 5,
			rows: 3
		},
		{
			header: 'Corporate Goal',
			text: `
- To lead and establish “AARSH REMEDIES” in the international market by providing cost effective innovative products.
- To reach every corner of India with high quality product range through power packed Direct Market & Franchisee Market.
- Core Organizational Culture, high degree of transparency, high qualified team who direct their way to success by implementing innovative, long terms strategies which helps company to identify and meet corporate governance, growth including core focus area of unmet medical needs.`,
			cols: 5,
			rows: 3
		},
		{
			image: "assets/images/corporate_goal.jpg",
			cols: 3,
			rows: 3
		},
	];

}
