<form [formGroup]="form" (ngSubmit)="onSubmit()">
	<div style="min-width: 350px; min-height: 410px;">
		<h2 mat-dialog-title>Add Product</h2>
		<mat-dialog-content>
			<mat-form-field>
				<mat-label>Product Name</mat-label>
				<input matInput formControlName="productName" required #name>
				<mat-error>
					<b>Product Name</b> is Required
				</mat-error>
				<mat-hint align="end"><b>{{ name.value.length }}</b></mat-hint>
			</mat-form-field>
			<br>
			<mat-form-field>
				<mat-label>Product Category</mat-label>
				<input matInput formControlName="productCategory" [value]="catValue">
			</mat-form-field>
			<br>
			<mat-form-field>
				<mat-label>Product Formulation</mat-label>
				<input matInput formControlName="productFormulation" required #formulation>
				<mat-error>
					<b>Product Formulation</b> is Required
				</mat-error>
				<mat-hint align="end"><b>{{ formulation.value.length }}</b></mat-hint>
			</mat-form-field>
			<br>
			<mat-form-field>
				<mat-label>Packing</mat-label>
				<input matInput formControlName="packing" required>
				<mat-error>
					<b>Packing Details</b> is Required
				</mat-error>
			</mat-form-field>
		</mat-dialog-content>
		<br><br>
		<mat-dialog-actions align="end">
			<button mat-raised-button color="warn" (click)="close()">Cancel</button>
			<button mat-raised-button color="primary" type="submit" [disabled]="!form.valid">Add Product</button>
		</mat-dialog-actions>
	</div>
</form>