<div class="center container">
	<br><br>
	<div class="header">
		<div class="">
			<span style="float: left;">
				<button class="fonts" mat-icon-button [routerLink]="['/admin-dashboard']"
					routerLinkActive="router-link-active" aria-label="Back" matTooltip="Back to Dashboard">

					<mat-icon>arrow_back</mat-icon>

				</button>
			</span>
			<!-- <mat-icon>admin_panel_settings</mat-icon> -->
			<h2>Settings</h2>
		</div>
	</div>
</div>
	<!-- 
	<div class="center-items">
		
		<mat-card appMaterialElevation>
			<mat-card-header class="" fxLayoutAlign="center center">
				<mat-card-title class="title" gdArea="title">
					<h2>Edit Login Credentials</h2>
				</mat-card-title>
			</mat-card-header>
			<br>
			<mat-card-content fxLayout="row wrap" fxLayoutAlign="center center">
				<form class="form">
					<mat-form-field class="ip">
						<mat-label>Email</mat-label>
						<input matInput id="email" placeholder="Type New Email" type="email" [value]="adminEmail">
						
						<mat-error>
							Please Enter a <b>Valid Email Address</b>
						</mat-error>
					</mat-form-field>
					
					<br>
					
					<mat-form-field class="ip">
						<mat-label>Password</mat-label>
						<input matInput id="pwd" [type]="hide ? 'password' : 'text'" placeholder="Password" id="password" name="password" required #pwd [value]="adminPassword">
						
						<mat-icon matSuffix (click)="hide = !hide" class="eyeIcon">
							{{ hide ? 'visibility_off' : 'visibility' }}
						</mat-icon>

						<mat-hint align="end"><b>{{ pwd.value.length }}</b></mat-hint>
					</mat-form-field>

					<div class="center">
						<button mat-raised-button color="warn" type="submit">
							Cancel
						</button>
						&nbsp;
						<button mat-raised-button color="primary" type="submit">
							<mat-icon>admin_panel_settings</mat-icon>
							Change
						</button>
					</div>
				</form>
			</mat-card-content>
		</mat-card>

		<mat-card appMaterialElevation>
			<mat-card-header class="" fxLayoutAlign="center center">
				<mat-card-title class="title" gdArea="title">
					<h2>Contact Query Email</h2>
				</mat-card-title>
			</mat-card-header>
			<br>
			<mat-card-content fxLayout="row wrap" fxLayoutAlign="center center">
				<form class="form">

					<mat-form-field class="chip-list">
						<mat-chip-list #chipList aria-label="Add Email">
						<mat-chip *ngFor="let mailReceipent of mailReceipents" [selectable]="selectable"
								[removable]="removable" (removed)="remove(mailReceipent)">
								{{ mailReceipent.email }}
							<mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
						</mat-chip>
						  <input placeholder="Add Email"
								[matChipInputFor]="chipList"
								[matChipInputSeparatorKeyCodes]="separatorKeysCodes"
								(matChipInputTokenEnd)="add($event)">
						</mat-chip-list>
					</mat-form-field>
					<br>
					
					<div class="center">
						<button mat-raised-button color="warn" type="submit">
							Cancel
						</button>
						&nbsp;
						<button mat-raised-button color="primary" type="submit">
							<mat-icon>admin_panel_settings</mat-icon>
							Change
						</button>
					</div>
				</form>
			</mat-card-content>
		</mat-card>
	</div>
	-->
<div class="mid">
	<mat-tab-group mat-align-tabs="center" [selectedIndex]="0" dynamicHeight>
		<mat-tab label="Login">
			<mat-card appMaterialElevation>
				<mat-card-header fxLayoutAlign="center center">
					<mat-card-title>Login Credentials</mat-card-title>
					<mat-card-subtitle></mat-card-subtitle>
				</mat-card-header>

				<mat-card-content>
					<form [formGroup]="credForm" (ngSubmit)="onCredentialsChange()">
						<mat-form-field class="ip">
							<mat-label>Email</mat-label>
							<input matInput id="email" placeholder="Type New Email" type="email" [value]="adminEmail" formControlName="email">

							<mat-error>
								Invalid Email Address
							</mat-error>
						</mat-form-field>

						<br>

						<mat-form-field class="ip">
							<mat-label>Password</mat-label>
							<input matInput id="pwd" [type]="hide ? 'password' : 'text'" placeholder="Password"	id="password" required #pwd [value]="adminPassword" formControlName="password">

							<mat-icon matSuffix (click)="hide = !hide" class="eyeIcon">
								{{ hide ? 'visibility_off' : 'visibility' }}
							</mat-icon>

							<mat-hint align="end"><b>{{ pwd.value.length }}</b></mat-hint>
						</mat-form-field>

						<div class="center">
							<button mat-raised-button color="primary" type="submit">
								<mat-icon>admin_panel_settings</mat-icon>
								Change
							</button>
						</div>
					</form>
				</mat-card-content>
			</mat-card>
		</mat-tab>

		<mat-tab label="Login OTP Email">
			<mat-card appMaterialElevation>
				<mat-card-header fxLayoutAlign="center center">
					<mat-card-title>Login Credentials</mat-card-title>
					<mat-card-subtitle></mat-card-subtitle>
				</mat-card-header>

				<mat-card-content>
					<form class="form">
						<mat-form-field class="ip">
							<mat-label>Login OTP Email</mat-label>
							<input matInput id="login_otp_email" placeholder="Type New Email" type="email"
								[value]="loginOTPEmail">

							<mat-error>
								Please Enter a <b>Valid Email Address</b>
							</mat-error>
						</mat-form-field>

						<br>
						<div class="center">
							<button mat-raised-button color="primary" type="submit">
								<mat-icon>admin_panel_settings</mat-icon>
								Change
							</button>
						</div>
					</form>
				</mat-card-content>
			</mat-card>
		</mat-tab>

		<mat-tab label="Contact Query">
			<mat-card class="container center" appMaterialElevation>
				<mat-card-header fxLayoutAlign="center center">
					<mat-card-title>Contact Query Email</mat-card-title>
				</mat-card-header>

				<mat-card-content>

					<form [formGroup]="queryForm" (ngSubmit)="onContactQuerySubmit()">
						<mat-form-field>
							<mat-chip-list required #chipList formControlName="email">
								<mat-chip color="primary" *ngFor="let email of queryForm.get('email').value"
									(removed)="removeEmailAddress(email)">
									{{ email }}
									<mat-icon matChipRemove>clear</mat-icon>
								</mat-chip>
								<input formControlName="emailHelper" placeholder="Add Emails Here"
									[matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
									(matChipInputTokenEnd)="addEmailAddress($event)">
							</mat-chip-list>
							<mat-error>
								Invalid Email Address
								<!-- {{ queryForm.get('email').errors | json }} -->
							</mat-error>
						</mat-form-field>

						<button mat-raised-button color="primary" type="submit"
							[disabled]="queryForm.invalid">Update</button>
					</form>

				</mat-card-content>
			</mat-card>
			<mat-divider></mat-divider>
			<div class="container">
				<mat-list>
					<div mat-subheader>Mat Chip Reference</div>
					<mat-list-item>
						<mat-icon mat-list-icon>attachment</mat-icon>
						<a href="https://stackoverflow.com/questions/55724694/email-validation-for-mat-chip-list" target="_blank" rel="noopener noreferrer">https://stackoverflow.com/questions/55724694/email-validation-for-mat-chip-list</a>
					</mat-list-item>
					<mat-list-item>
						<mat-icon mat-list-icon>attachment</mat-icon>
						<a href="https://stackblitz.com/edit/mat-chip-demo-dhm17z?file=src%2Fapp%2Fapp.component.html" target="_blank"	rel="noopener noreferrer">https://stackblitz.com/edit/mat-chip-demo-dhm17z?file=src%2Fapp%2Fapp.component.html</a>
					</mat-list-item>
					<mat-list-item>
						<mat-icon mat-list-icon>attachment</mat-icon>
						<a href="https://stackblitz.com/edit/angular-material-chip-list-email-input?file=src%2Fapp%2Fchip-list-email-input%2Fchip-list-email-input.component.ts" target="_blank" rel="noopener noreferrer">https://stackblitz.com/edit/angular-material-chip-list-email-input?file=src%2Fapp%2Fchip-list-email-input%2Fchip-list-email-input.component.ts</a>
					</mat-list-item>
					<mat-list-item>
						<mat-icon mat-list-icon>attachment</mat-icon>
						<a href="https://stackoverflow.com/questions/66561090/reusable-angular-material-mat-chip-list-input-with-email-validation" target="_blank" rel="noopener noreferrer">https://stackoverflow.com/questions/66561090/reusable-angular-material-mat-chip-list-input-with-email-validation</a>
					</mat-list-item>
				</mat-list>
			</div>

		</mat-tab>

	</mat-tab-group>
</div>

<!-- 
<form [formGroup]="form" class="form" (ngSubmit)="onChange(form)">
	<mat-form-field class="ip">
		<mat-label>Email</mat-label>
	
		<input matInput id="email" placeholder="Type New Email" type="email" formControlName="email" required #email>

		<mat-error>
			Please Enter a <b>Valid Email Address</b>
		</mat-error>
	</mat-form-field>
	
	<br>
	
	<mat-form-field class="ip">
		<mat-label>Password</mat-label>
	
		<input matInput id="pwd" [type]="hide ? 'password' : 'text'" placeholder="Password" id="password" name="password" required #pwd formControlName="password" >

		<mat-icon matSuffix (click)="hide = !hide" class="eyeIcon">
			{{ hide ? 'visibility_off' : 'visibility' }}
		</mat-icon>

		<mat-hint align="end"><b>{{ pwd.value.length }}</b></mat-hint>
	</mat-form-field>

	<div class="center">
		&nbsp;
		<button mat-raised-button color="primary" type="submit">
			<mat-icon>admin_panel_settings</mat-icon>
			Change
		</button>
	</div>
</form>
-->