<div class="hom">
	
	<carousel 
		[images]="landing"
		[loop]="true"
		[autoplay]="true"
		[autoplayInterval]="4500"
		[transitionDuration]="500"
		[overflowCellsLimit]="1"
		[pauseOnHover]="false"
		[cellsToShow]="1"
		[arrows]="false"
		[height]="650"
		[dots]="true"
		>
	</carousel>
</div>

<div class="ng-particles">
	<ng-particles id="particles" [options]="background_mask" (particlesLoaded)="particlesLoaded($event)" (particlesInit)="particlesInit($event)"></ng-particles>
</div>
<br>
<div>
	<mat-card>
		<mat-card-header>
			<mat-card-title>
				<h1>AT A GLANCE</h1>
			</mat-card-title>
		</mat-card-header>
		<mat-card-content>
			<p>
				Aarsh Remedies Pvt. Ltd. is Ahmedabad based pharmaceutical company engaged in developing & marketing broad range of pharmaceuticals globally with its core focus on making available quality pharmaceutical products at affordable cost for human health.
			</p>
			<p>
				We, at Aarsh Remedies, value the relationship with our clients always and believe in Customer Centric approach and always eager for the value delivered to our clients. To its unparalleled service and unsurpassed quality, Aarsh Remedies has got all that it takes to grow its commitment to excellence.
			</p>
		</mat-card-content>
	</mat-card>
</div>
<br>

<ng-image-slider 
	[images]="products_slides"
	[infinite]="true"
	[animationSpeed]="0.5"
	[imageSize]="imageSizeOptions"
	[autoSlide]="autoSlideOptions"
	[manageImageRatio]="true"
	[lazyLoading]="true"
	[showArrow]="false"
	#nav>
</ng-image-slider>

<div class="center">
	<button mat-mini-fab color="primary"  (click)="prevImageClick()" aria-label="Previous Image">
		<mat-icon>chevron_left</mat-icon>
	</button>
	&nbsp;
	<button mat-mini-fab color="primary"  (click)="nextImageClick()" aria-label="Next Image">
		<mat-icon>chevron_right</mat-icon>
	</button>
</div>
<br>
<script src="https://cdn.jsdelivr.net/particles.js/2.0.0/particles.min.js"></script>

<scroll-to-top></scroll-to-top>
