import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { pipe } from 'rxjs';

import { AdminService } from '../services/admin.service';
import { AuthService } from "../services/auth.service";

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

declare var $: any;

@Component({
	selector: 'app-admin',
	templateUrl: './admin.component.html',
	styleUrls: ['./admin.component.css']
})

export class AdminComponent implements OnInit {

	hide = true;

	providedOTP: any;
	generatedOTP: any;

	form: FormGroup

	public constructor(private title : Title, private router:Router, public dialog: MatDialog, private _auth: AuthService, public _admin: AdminService, private fb: FormBuilder, private snackbar: MatSnackBar)
	{
		// To set Title
		this.title.setTitle("Admin Panel | Aarsh Remedies Pvt. Ltd.");
		
		this._auth.adminLogin().subscribe(
			res => {
				this.router.navigate(['/admin-dashboard'])
			},
			err => {
				if(err.status === 401) {
				}
				localStorage.removeItem("key")
			}
		)
	}
	
	ngOnInit(): void {
		this.form = this.fb.group({
			email: ['', [Validators.required, Validators.email]],
			password: ['', [Validators.required]],
		})
	}

	
	onSubmit() {
		const email = this.form.value.email
		const password = this.form.value.password

		if (email !== "" && password !== "") {
			this._admin.verifyCredentials(email, password).pipe(take(1)).subscribe(
				err => {
				},
				res => {
					if(res.status === 200) {
						this.sendOTP()
					}
					if(res.status === 403) {
						this.snackbar.open("Incorrect Credentials", 'Dismiss', {
							duration: 2500
						})
					}
				},
			)
		}
		else {
			this.snackbar.open("Incorrect Credentials", 'Dismiss', {
				duration: 2500
			})
		}
	}
	
	// onSubmit() {
	// 	const email = this.form.value.email
	// 	const password = this.form.value.password
	// 	if (email === this.adminEmail && password === this.adminPassword) {
	// 		this.sendOTP()
	// 	}
	// 	else {
	// 		console.log("NOT Verified")
	// 		this.snackbar.open("Incorrect Credentials", 'Dismiss', {
	// 			duration: 2500
	// 		})
	// 	}
	// }	

	generateOTP()
	{
		// Declare a string variable  
		// which stores all string 
		var string = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ._-'; 
		let OTP = ''; 
		
		// Find the length of string 
		var len = string.length; 
		for (let i = 0; i < 8; i++ ) { 
			OTP += string[Math.floor(Math.random() * len)]; 
		} 
		return OTP; 
	}

	sendOTP() {
		this.generatedOTP = this.generateOTP();
		this._auth.sendMail(this.generatedOTP).pipe(take(1)).subscribe(
			res => {
				if (res.status === 204) {
					this.snackbar.open('Error Occured, please try again', 'Dismiss', {
						duration: 3500
					});
					return;
				}
				if (res.status === 400) {
					this.snackbar.open('Error sending email', 'Dismiss', {
						duration: 3500
					});
					return;
				}
				console.log(res)
			}
		);
		

		// For Dialog Configuration
		const dialogConfig = new MatDialogConfig();

		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;
		dialogConfig.data = {
			generatedOTP: this.generatedOTP
		}

		const dialogRef = this.dialog.open(
			VerifyOTP, dialogConfig)

		// dialogRef.afterClosed().pipe(take(1)).subscribe(res => {
		// 	console.log("Dialog Closed - In admin Component");
		// 	console.log(res);
		// });
	}
}


/* VerifyOTP Component */
@Component({
	selector: 'verifyOTPDialog',
	templateUrl: 'verifyOTPDialog.html',
	styleUrls: ['verifyOTPDialog.css']
})

export class VerifyOTP implements OnInit, OnDestroy {
	providedOTP: any;
	generatedOTP: any;
	
	loginAttempts: number;

	form: FormGroup
	verification: any;

	loading: boolean = false;
	disableBtn: boolean = true; 

	constructor(public dialogRef: MatDialogRef<VerifyOTP>,
		@Inject(MAT_DIALOG_DATA) public data: DialogData,
		private snackbar: MatSnackBar, private _auth: AuthService,
		private fb: FormBuilder, private router:Router, public dialog: MatDialog,) {

		this.loginAttempts = 3;
		this.snackbar.open('An OTP has been sent to registered email address', 'Dismiss', {
			duration: 3500
		});
		this.delayTenSec();
	}
		
	ngOnInit(): void {
		this.form = this.fb.group({
			providedOTP: ''
		})
	}

	cancel()
	{
		this.dialogRef.close();
		this.snackbar.open('OTP Verification Cancelled', 'Dismiss', {
			duration: 3500,			
		});
	}
	
	delayTenSec() {
		setTimeout (() => {
			this.disableBtn = false;
		}, 10000);
	}

	verify(form) {
		if(this.form.value.providedOTP !== '') {

			this._auth.verifyOTP(this.data.generatedOTP, this.form.value.providedOTP).pipe(take(1)).subscribe(
				res => {
					console.log("Authorized")
					localStorage.setItem("key", res.token)
					this.dialogRef.close()
					this.snackbar.open('Logged in as Admin', 'Dismiss', {
						duration: 3500,			
					});
					this.router.navigate(['/admin-dashboard'])
				},
				err => {
					console.log("Not Authorized");
					if(this.loginAttempts === 0)
					{
						this.dialogRef.close();
						this.snackbar.open("3 Failed Attempts. Please Login Again.", 'Dismiss', {
							duration: 3500
						})
					}
					else
					{
						this.snackbar.open("Incorrect OTP - " + this.loginAttempts + " Attempt(s) remaining", 'Dismiss', {
							duration: 3500
						})
		
						this.loginAttempts--;
					}
				}
			);
		}
		else {
			this.snackbar.open('Please Enter OTP', 'Dismiss', {
				duration: 3500,			
			});
		}
	}

	resendOTP() {
		this.dialogRef.close();

		this._auth.sendMail(this.data.generatedOTP).pipe(take(1)).subscribe(
			res => console.log(res)
		);

		// For Dialog Configuration
		const dialogConfig = new MatDialogConfig();

		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;
		dialogConfig.data = {
			'resentOTP': true,
			generatedOTP: this.data.generatedOTP
		}

		this.snackbar.open('An OTP has been re-sent to registered email address', 'Dismiss', {
			duration: 3500
		});

		const dialogRef = this.dialog.open(VerifyOTP, dialogConfig)
	}

	ngOnDestroy(): void {
		// this.verification.unsubscribe();
	}
}

export interface DialogData {
	generatedOTP: number
}