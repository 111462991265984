<div style="min-width: 250px; min-height: 200px;">
	<h2 mat-dialog-title>Confirm Delete Category ?</h2>
	<mat-dialog-content>
		<h3>Category Name</h3>
		<p>{{ name }}</p>
	</mat-dialog-content>

	<mat-dialog-actions align="end">
		<button mat-raised-button color="primary" (click)="close($event)">Cancel</button>
		<button mat-raised-button color="warn" id="delete" (click)="onSubmit($event)">Delete</button>
	</mat-dialog-actions>
</div>