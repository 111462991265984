import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GlobalConstants } from '../common/global-constants';

@Injectable({
	providedIn: 'root'
})

export class AuthService {

	basePath = GlobalConstants.apiURL;

	private _sendOTPURL = this.basePath + "sendOTP"
	private _verifyOTPURL = this.basePath + "verifyOTP"
	private _adminLoginURL = this.basePath + "admin"

	constructor(private _http: HttpClient) { }

	sendMail(otp) {
		return this._http.post<any>(this._sendOTPURL, {"generatedOTP": otp})
	}
	
	verifyOTP(gotp, potp) {
		return this._http.post<any>(this._verifyOTPURL, {
			"generatedOTP": gotp,
			"providedOTP": potp
		})
	}

	isLoggedIn() {
		return !!localStorage.getItem("key")
	}

	getToken(){
		return localStorage.getItem("key")
	}

	adminLogin() {
		return this._http.post<any>(this._adminLoginURL, {})
	}
}
