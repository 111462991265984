import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AdminService } from '../services/admin.service';

@Component({
	selector: 'app-products',
	templateUrl: './products.component.html',
	styleUrls: ['./products.component.css']
})

export class ProductsComponent implements OnInit {
	currentPath:string;
	Products: any;

	public constructor(private title : Title, private path:Router, private _admin: AdminService)
	{
		// To set Title
		this.title.setTitle("Products | Aarsh Remedies Pvt. Ltd.");

		this._admin.readCategories().subscribe(
			data => {
				this.Products = data;
			},
		);
	}


	ngOnInit(): void {
		this.currentPath = this.path.url;
	}

	// For Card Elevation
	defaultElevation = 2;
	raisedElevation = 6;

}
