import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { GlobalConstants } from '../common/global-constants';

@Injectable({
	providedIn: 'root'
})
export class IpService {

	// basePath = GlobalConstants.apiURL;

	private url = "https://ipapi.co/json/";
	// private _getIP = this.basePath + "getIP"

	constructor(private _http: HttpClient) { }

	public getIPAddress() {
		return this._http.get(this.url);
	}

	// public getIP() {
	// 	return this._http.post(this._getIP, {});
	// }
}
