<div class="center container">
	<br><br>
	<div class="header">
		<div class="container">
			<span style="float: left;">
				<button 
					class="fonts"
					mat-icon-button
					[routerLink]="['/admin-dashboard']"
					routerLinkActive="router-link-active"
					aria-label="Back"
					matTooltip="Back to Dashboard">
					<mat-icon>arrow_back</mat-icon>
				</button>
			</span>
			<h1>{{ catName }}</h1>
		</div>
	</div>
</div>
<div class="container">
	<span>
		<button class="fonts" mat-raised-button color="primary" (click)="addProduct()">
			<mat-icon>add</mat-icon>
			Add Product
		</button>
	</span>
	<span style="float: right;">
		<button mat-raised-button color="warn" (click)="logout()">
			<mat-icon>exit_to_app</mat-icon>
			Logout
		</button>
	</span>
	<br>
	<div>
		<h1 class="center">All Products</h1>
		<h2 style="float: right;">Total Products: {{ len }}</h2>
	</div>

	<!-- Search Bar Above Table -->
	<!-- QuestionMark is Elvis Operator -->
	<div *ngIf="dataSource?.data.length > 0"> 
		<mat-form-field>
			<mat-icon matPrefix>search</mat-icon>
			<mat-label>Search Products</mat-label>
			<input matInput [(ngModel)]="query" (keyup)="applyFilter()" placeholder="Search Products">
			<button mat-icon-button matSuffix aria-label="Clear" (click)="onClear()" *ngIf="query" aria-label="Clear" matTooltip="Clear Input">
				<mat-icon>close</mat-icon>
			</button>
		</mat-form-field>
	</div>

	<!-- Table -->
	<div>
		<table mat-table [dataSource]="dataSource" matSort appMaterialElevation>

			<!-- No. Column -->
			<ng-container matColumnDef="no">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>No.</th>
				<td mat-cell *matCellDef="let i = index;">{{ i + 1 }}</td>
			</ng-container>

			<!-- Product Name Column -->
			<ng-container matColumnDef="product_name">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Product Name</th>
				<td mat-cell *matCellDef="let element">
					{{ element.product_name }}
				</td>
			</ng-container>

			<!-- Product Formulation Column -->
			<ng-container matColumnDef="product_formulation">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Product Formulation</th>
				<td mat-cell *matCellDef="let element;">{{ element.product_formulation }}</td>
			</ng-container>
			
			<!-- Packing Column -->
			<ng-container matColumnDef="packing">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Product Packing</th>
				<td mat-cell *matCellDef="let element;">{{ element.packing }}</td>
			</ng-container>
			
			<!-- Edit & Delete Icons -->
			<ng-container matColumnDef="actions">
				<th mat-header-cell *matHeaderCellDef>Options</th>
				<td mat-cell *matCellDef="let row; let i = index;" [id]='i+1'>
					<button mat-icon-button color="primary" matTooltip="Modify" (click)="modifyProduct($event)" [attr.id]="i+1">
						<mat-icon>edit</mat-icon>
					</button>
					<button mat-icon-button color="warn" matTooltip="Delete" (click)="deleteProduct($event)" [attr.id]="i+1">
						<mat-icon>delete</mat-icon>
					</button>
				</td>
			</ng-container>
			
			<ng-template [ngIf]="dataSource?.data.length === 0">
				<tr mat-footer-row *matFooterRowDef="['noRecord']">No Products Found</tr>
			</ng-template>

			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
		</table>
	</div>
</div>

<scroll-to-top></scroll-to-top>

<br>