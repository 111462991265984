<div class="container">
	<br><br>
	<div class="svg">
		<img src="assets/svg/medical_care.svg" alt="Medical Care SVG">
	</div>
	<div class="header">
		<h1>Our Products</h1>
	</div>
	<br>
	
	<div
		fxLayout="row wrap" 
		fxLayout.lt-sm="column" 
		fxLayoutGap="16px" 
		fxLayoutAlign="flex-start">
		
		<ng-container *ngFor="let category of Products">
			<div
				fxFlex="0 1 calc(33.3% - 16px)"
				fxFlex.lt-md="0 1 calc(50% - 16px)"
				fxFlex.lt-sm="100%">

				<div 
				gdAreas="title title | content content | footer footer"
				gdGap="12px"
				gdRows="auto auto auto"
				gdAreas.lt-md="title | content | footer"
				gdRows.lt-md="auto auto auto">
			
				<mat-card appMaterialElevation>
					<mat-card-header fxLayoutAlign="center center">
						<mat-card-title class="title" gdArea="title">
							<a href="{{ currentPath }}/{{ category.name }}">{{ category.displayName }}</a>
						</mat-card-title>
					</mat-card-header>
					<br>
					<a href="{{ currentPath }}/{{ category.name }}">
						<img mat-card-image src="{{ category.image }}"
						alt="Photo of {{ category.name }}" class="card-image content"
						gdArea="content">
					</a>
				</mat-card>
				</div>
			</div>
		</ng-container>
	</div>
</div>
<scroll-to-top></scroll-to-top>
<br><br>
