<div class="container">
	<br>
	<div class="header">
		<h1>{{ catName }}</h1>
		<mat-divider></mat-divider>
	</div>
	<br>
	<div *ngIf="dataSource?.data.length > 0"> 
		<mat-form-field>
			<mat-icon matPrefix>search</mat-icon>
			<mat-label>Search Products</mat-label>
			<input matInput [(ngModel)]="query" (keyup)="applyFilter()" placeholder="Search Products">
			<button mat-icon-button matSuffix aria-label="Clear" (click)="onClear()" *ngIf="query" aria-label="Clear" matTooltip="Clear Input">
				<mat-icon>close</mat-icon>
			</button>
		</mat-form-field>
	</div>
	
	<div class="product-detail" [hidden]="!doesTableHaveData">
		<table mat-table [dataSource]="dataSource" matSort appMaterialElevation>

			<ng-container matColumnDef="no">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>No.</th>
				<td mat-cell *matCellDef="let i = index;">{{ i + 1}}</td>
			</ng-container>

			<ng-container matColumnDef="product_name">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Product Name</th>
				<td mat-cell *matCellDef="let element; let i=index;" class="name">
					{{ element.product_name }}
				</td>
			</ng-container>

			<ng-container matColumnDef="product_formulation">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Product Formulation</th>
				<td mat-cell *matCellDef="let element;">{{ element.product_formulation }}</td>
			</ng-container>

			<ng-container matColumnDef="packing">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Product Packing</th>
				<td mat-cell *matCellDef="let element;">{{ element.packing }}</td>
			</ng-container>
			
			<ng-container matColumnDef="noData">
				<tr mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
					No records found.
				</tr>
			</ng-container>
			  
			<ng-container matColumnDef="loading">
				<tr mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
					Loading, please wait...
				</tr>
			</ng-container>

			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

			<tr mat-footer-row *matFooterRowDef="['noData']" [hidden]="dataSource && dataSource.data.length > 0"></tr>
			<tr mat-footer-row *matFooterRowDef="['loading']" [hidden]="dataSource !== undefined"></tr>
		</table>
	</div>
	<br>
</div>

<scroll-to-top></scroll-to-top>

<br>
