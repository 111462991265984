import { FormBuilder, FormGroup, Validators, FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { take } from 'rxjs/operators';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { ContactService } from "../services/contact.service";
import { IpService } from "../services/ip.service"
import { Router } from '@angular/router';
import { GlobalConstants } from '../common/global-constants';

declare var _: any; 

@Component({
	selector: 'app-contact',
	templateUrl: './contact.component.html',
	styleUrls: ['./contact.component.css']
})

export class ContactComponent implements OnInit {

	queryForm: FormGroup;
	countryCodes: any = [];
	formData = {};
	isDisabled = false;
	primary_email = GlobalConstants.primary_email;

	client_info: any = {};

	public constructor(private title : Title, public dialog: MatDialog, private fb: FormBuilder, private _contact: ContactService, private snackbar: MatSnackBar, private router: Router, private ip: IpService)
	{
		// To set Title
		this.title.setTitle("Contact Us | Aarsh Remedies Pvt. Ltd.");
	}

	ngOnInit(): void {
		this.queryForm = this.fb.group({
			name: ['', Validators.required],
			company: [''],
			email: ['', [Validators.required, Validators.email]],
			phone: [''],
			msg: ['', [Validators.required]],
		});
		this.ip.getIPAddress().subscribe((response: any) => {
			this.client_info = response;
		});
	}

	call() {
		location.href = "tel:+919979896925";
	}

	whatsapp() {
		window.open("https://wa.me/919979896925?text=Hello, I need to inquire for some of your products", '_blank');
	}
	
	openDialog():void {
		const dialogRef = this.dialog.open(ContactDialog);
	}

	onSubmit() {
		if (this.queryForm.invalid) {
			return;
		}
		else {
			this.isDisabled = true;
			this.formData = {
				"company": this.queryForm.value.company,
				"email": this.queryForm.value.email,
				"msg": this.queryForm.value.msg,
				"name": this.queryForm.value.name,
				"phone": this.queryForm.value.phone,
				"ip_info": this.client_info
			}
			
			this._contact.contactForm(this.formData).pipe(take(1)).subscribe(
				err => {
					this.snackbar.open('Cannot connect to server. Please try again after sometime', 'Dismiss', {
						duration: 10000
					});
				},
				res => {
					if (res.status === 200) {
						this.snackbar.open('Your Message has been received successfully', 'Dismiss', {
							duration: 0
						});
					}
					else {
						this.snackbar.open('There was an error sending the message, please try again', 'Dismiss', {
							duration: 0
						});
					}
				},
			);
			this.isDisabled = false;
		}
	}
}

@Component({
	selector: 'contactDialog',
	templateUrl: 'contactDialog.html',
})
export class ContactDialog {}


/** Error when invalid control is dirty, touched, or submitted. */
export class emailErrorStateMatcher implements ErrorStateMatcher {
	isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
		const isSubmitted = form && form.submitted;
		return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
	}
}


/* FormResponse Component */
@Component({
	selector: 'formResponse',
	templateUrl: 'formResponse.html',
	// styleUrls: ['']
})

export class FormResponse implements OnInit, OnDestroy {

	header: string;
	content: string;

	constructor(public dialogRef: MatDialogRef<FormResponse>,
		@Inject(MAT_DIALOG_DATA) public data: DialogData,
		private snackbar: MatSnackBar, private router:Router, public dialog: MatDialog,) {	
	}
		
	ngOnInit(): void {
	}

	ngOnDestroy(): void {
		// this.verification.unsubscribe();
	}
}

export interface DialogData {
	header: string
	content: string
}
