<div class="center">
	<br><br>
	<div class="header">
		<h1>Admin Dashboard</h1>
	</div>
</div>
<div class="container">
	<span>
		<button class="fonts" mat-raised-button color="primary" (click)="addCategory()">
			<mat-icon>add</mat-icon>
			Add Category
		</button>
	</span>
	
	<span>
		<button class="fonts" mat-raised-button color="primary" [routerLink]="['/admin-dashboard/search']" matTooltip="Advanced Search">
			<mat-icon>search</mat-icon>
			Search All Products
		</button>
	</span>

	<span style="float: right;">
		<button
			mat-raised-button
			color="primary"
			[routerLink]="['/admin-dashboard/settings']"
			routerLinkActive="router-link-active">
				<mat-icon>settings</mat-icon>
				Settings
		</button>
		&nbsp;
		<!-- <button mat-icon-button color="primary">
			<mat-icon>settings</mat-icon>
		</button>
		&nbsp; -->
		<!-- <button mat-fab color="primary">
			<mat-icon>settings</mat-icon>	
		</button>
		&nbsp; -->
		<button mat-raised-button color="warn" (click)="logout()" matTooltip="Logout">
			<mat-icon>exit_to_app</mat-icon>
			Logout
		</button>
	</span>
	<br>
	<h1 class="center">All Categories</h1>
	<h2 style="float: right;">Total Products: {{ totalProducts }}</h2>
	
	<!-- Table -->
	<div>
		<table mat-table [dataSource]="dataSource" matSort appMaterialElevation>

			<!-- No. Column -->
			<ng-container matColumnDef="no">
				<th mat-header-cell *matHeaderCellDef>No.</th>
				<td mat-cell *matCellDef="let i = index;">{{ i + 1 }}</td>
			</ng-container>

			<!-- Name Column -->
			<ng-container matColumnDef="displayName">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Category</th>
				<td mat-cell *matCellDef="let element">
					<a fxFlexFill [routerLink]="'/admin-dashboard/category/' + element.name" class="mat-row-link">{{ element.displayName }}</a>
					<!-- {{ element.displayName }} -->
				</td>
			</ng-container>

			<!-- No. of Products Column -->
			<ng-container matColumnDef="no-of-products">
				<th mat-header-cell *matHeaderCellDef>No. of Products Available</th>
				<td mat-cell *matCellDef="let i = index; let element" [id]='i+1'>
					<a fxFlexFill [routerLink]="'/admin-dashboard/category/' + element.name" class="mat-row-link">{{ no_of_products[i] }}</a>
					<!-- {{ no_of_products[i] }} -->
				</td>
			</ng-container>
			
			<!-- Edit & Delete Icons -->
			<ng-container matColumnDef="actions">
				<th mat-header-cell *matHeaderCellDef>Options</th>
				<td mat-cell *matCellDef="let row; let i = index;" [id]='i+1'>
					<!-- <button mat-icon-button color="primary" matTooltip="Modify" (click)="modifyCategory($event)" [attr.id]="i+1">
						<mat-icon>edit</mat-icon>
					</button> -->
					<button mat-icon-button color="warn" matTooltip="Delete" (click)="deleteCategory($event)" [attr.id]="i+1">
						<mat-icon>delete</mat-icon>
					</button>
				</td>
			</ng-container>
			
			<!-- Launch Icon -->
			<ng-container matColumnDef="launch">
				<th mat-header-cell *matHeaderCellDef>Open</th>
				<td mat-cell *matCellDef="let element;">
					<button 
					mat-icon-button 
					color="primary" 
					matTooltip="Open"
					[routerLink]="'/admin-dashboard/category/' + element.name">
						<mat-icon>launch</mat-icon>
					</button>
				</td>
			</ng-container>

			<ng-template [ngIf]="dataSource?.data.length === 0">
				<tr mat-footer-row *matFooterRowDef="['noRecord']"></tr>
			</ng-template>

			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
		</table>
	</div>
</div>

<scroll-to-top></scroll-to-top>