<form [formGroup]="form" (ngSubmit)="verify(form)">
	<div class="content">
		<h2 mat-dialog-title>Verify OTP</h2>
		<mat-dialog-content>
			<mat-form-field>
				<mat-label>Enter OTP</mat-label>
				<input matInput placeholder="Enter OTP" formControlName="providedOTP" #otp>
				<!-- <mat-hint ><strong>OTP Sent Successfully</strong></mat-hint> -->
				<mat-hint align="end"><strong>{{ otp.value.length }} / 12</strong></mat-hint>
			</mat-form-field>
			<br><br>
			<button mat-stroked-button color="accent" [disabled]="disableBtn" (click)="resendOTP()">Resend OTP</button>
		</mat-dialog-content>

		<mat-dialog-actions align="end">
			<button mat-raised-button color="primary" type="submit" [disabled]="loading">Verify</button>
			<button mat-raised-button mat-dialog-close color="warn" (click)="cancel()">Cancel</button>

			<!-- <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar> -->
		</mat-dialog-actions>
	</div>
</form>

<!-- 
<mat-dialog-actions align="end">
	<button mat-raised-button mat-dialog-close color="warn">Cancel</button>
	<button mat-raised-button [mat-dialog-close]="data.providedOTP" color="primary">Verify</button>
</mat-dialog-actions>
-->