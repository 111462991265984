<form [formGroup]="form" (ngSubmit)="onSubmit()">
	<div style="min-width: 350px; min-height: 300px;">
		<h2 mat-dialog-title>Add Category</h2>
		<mat-dialog-content>
			<mat-form-field>
				<mat-label>Category Name</mat-label>
				<input matInput formControlName="categoryName" required placeholder="Ex. skin-cosmetics" #cat>

				<mat-error>
					<b>Category Name</b> is Required
				</mat-error>

				<mat-hint align="end"><b>{{ cat.value.length }}</b></mat-hint>
			</mat-form-field>
			<br>
			<mat-form-field>
				<mat-label>Display Name</mat-label>
				<input matInput formControlName="displayName" required placeholder="Ex. Skin & Cosmetics" #display>

				<mat-error>
					<b>Display Name</b> is Required
				</mat-error>

				<mat-hint align="end"><b>{{ display.value.length }}</b></mat-hint>
			</mat-form-field>
			<br>
			<mat-form-field>
				<mat-label>Description</mat-label>
				<input matInput formControlName="description" required placeholder="Ex. Our range for this products are..." #description>

				<mat-error>
					<b>Description</b> is Required
				</mat-error>
				
				<mat-hint align="end"><b>{{ description.value.length }}</b></mat-hint>
			</mat-form-field>

			<!-- Image Upload -->
			<!-- <mat-form-field>
				<mat-file-upload
					[labelText]="'Select an image to upload:'"
					[selectButtonText]="'Choose Image'"
					[selectFilesButtonType]="button"
					[uploadButtonText]="'Submit'"
					[uploadButtonType]="submit"
					[allowMultipleFiles]="false"
					[showUploadButton]="true"
					[customSvgIcon]="'close_custom'"
					[acceptedTypes]="'.png, .jpg, .jpeg'"
					(uploadClicked)="onImageUploadClicked($event)"
					(selectedFilesChanged)="onSelectedFilesChanged($event)"
					>
					</mat-file-upload>
				</mat-form-field>
			-->
			<br>
			<!--
			<mat-form-field>
				<mat-label>File Name (Same as Category Name)</mat-label>
				<input matInput formControlName="fileName" required placeholder="Ex. skin-cosmetics" (keyup)="check()" #file>
				<span matSuffix>.json&nbsp;</span>

				<mat-error>
					<b>File Name</b> must be same as Category Name
				</mat-error>

				<mat-hint align="end"><b>{{ file.value.length }}</b></mat-hint>
			</mat-form-field>
			-->
		</mat-dialog-content>

		<mat-dialog-actions align="end">
			<button mat-raised-button mat-dialog-close id="cancel" color="warn" (click)="close()">Cancel</button>
			<button mat-raised-button id="add" color="primary" type="submit" [disabled]="!form.valid" id="addCategory">Add Category</button>
		</mat-dialog-actions>
	</div>
</form>