import { Component } from '@angular/core';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";

// import * as AOS from "aos";

declare var $ : any;

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})

export class AppComponent {
	title = 'aarsh-remedies';
	constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer)
	{
		// For Custom mat-icon
		// Usage : <mat-icon svgIcon="name"></mat-icon>
		this.matIconRegistry.addSvgIcon(
			"tablet",
			this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svg/medical-icons/tablets.svg")
		);
		
		this.matIconRegistry.addSvgIcon(
			"whatsapp",
			this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svg/whatsapp.svg")
		);
		
		this.matIconRegistry.addSvgIcon(
			"telegram",
			this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svg/telegram.svg")
		);
		
		this.matIconRegistry.addSvgIcon(
			"signal",
			this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svg/signal.svg")
		);
		
		this.matIconRegistry.addSvgIcon(
			"linkedin",
			this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svg/linkedin.svg")
		);
		
		this.matIconRegistry.addSvgIcon(
			"linkedin-white",
			this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svg/linkedin-white.svg")
		);
	}

	ngOnInit(): void {}
}
