import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css']
})
export class PageNotFoundComponent implements OnInit {

  public constructor(private title : Title)
	{
		// To set Title
		this.title.setTitle("Page Not Found | Aarsh Remedies Pvt. Ltd.");
	}

  ngOnInit(): void {
  }

}
