import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { take } from 'rxjs/operators';

import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';

import { AdminService } from '../services/admin.service';
import { AuthService } from '../services/auth.service';

@Component({
	selector: 'app-admin-search',
	templateUrl: './admin-search.component.html',
	styleUrls: ['./admin-search.component.css']
})

export class AdminSearchComponent implements OnInit {

	displayedColumns: string[] = ["no", "product_name", "launch"];
	query: string;
	doesTableHaveData: boolean;
	dataSource: any;
	allProducts: string[] = [];


	public constructor(private title : Title, private _admin: AdminService, private _auth: AuthService, public dialog: MatDialog) {
		/* To set Title */
		this.title.setTitle("Advanced Search | Admin | Aarsh Remedies Pvt. Ltd.");

		this._admin.readCategories().pipe(take(1)).subscribe(
			data => {
				for(let i = 0; i < data.length; i++) {
					this._admin.readFileLength(data[i].fileName).subscribe(res => {
						this.allProducts.push(res["products"]);
					})
				}
				console.log(this.allProducts);
				
				this.dataSource = new MatTableDataSource(this.allProducts);

			}
		)
	}

	ngOnInit(): void {
	}

	applyFilter() {
		this.dataSource.filter = this.query.trim().toLowerCase();
		if(this.dataSource.filteredData.length > 0) {
			this.doesTableHaveData = true;
		}
		else
		{
			this.doesTableHaveData = false;
		}
	}

	onClear()
	{
		this.query = "";
		this.applyFilter();
	}

}
