import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { take } from 'rxjs/operators';

import { MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';

import { AdminService } from '../services/admin.service';
import { AuthService } from '../services/auth.service';

@Component({
	selector: 'app-admin-product-details',
	templateUrl: './admin-functionalities.component.html',
	styleUrls: ['./admin-functionalities.component.css']
})

export class AdminFunctionalitiesComponent implements OnInit {
	
	private _subRoute: any;
	slug: string;
	catName: string;
	catValue: any;
	fileData: any;
	len: any;
	productDetails: string[];
	dataSource: any;
	doesTableHaveData: boolean = true;
	displayedColumns: string[] = ["no", "product_name", "product_formulation", "packing", "actions"];

	query: string;

	@ViewChild(MatSort, { static: true }) sort: MatSort;

	public constructor(private title : Title, private _route: ActivatedRoute, private router: Router, private _admin: AdminService, private _auth: AuthService, private snackbar: MatSnackBar, public dialog: MatDialog)
	{	
		this._auth.adminLogin().subscribe(
			res => {
				// this.snackbar.open('Logged in as Admin', 'Dismiss', {
				// 	duration: 3500,			
				// });
			},
			err => {
				localStorage.removeItem("key")
				this.router.navigate(['/products/categories/aarsh/remedies/pvt/ltd'])
			}
		)

		// To get Parameters
		this._subRoute = this._route.params.subscribe(params => {
			this.slug = params['slug'];
		})
		

		this._admin.readFile(this.slug).subscribe(
			data => {
				this.catValue = data["details"]["name"];
				this.catName = data["details"]["displayName"];
				
				/* To set Title */
				this.title.setTitle(this.catName + " | Admin | Aarsh Remedies Pvt. Ltd.");

				this.fileData = data["products"];
				this.len = data["products"].length;
				this.dataSource = new MatTableDataSource(data["products"]);

				/* For Sorting in table */
				this.dataSource.sort = this.sort;
			}
		)
	}

	ngOnInit(): void {

		// /* To set Title */
		// this.title.setTitle(this.catName + " | Aarsh Remedies Pvt. Ltd.");
	}

	addProduct(){
		// For Dialog Configuration
		const dialogConfig = new MatDialogConfig();

		dialogConfig.autoFocus = true;
		dialogConfig.data = {
			catName: this.catName,
			catValue: this.catValue
		}

		const dialogProduct = this.dialog.open(addProductComponent, dialogConfig)
	}
	
	filterByID(id: number)
	{
		return this.fileData.filter(function(fileData)
		{
			return (fileData[id-1] === this.slug);
		})[0];
	}
	
	modifyProduct(event) {
		let target = event.target.attributes.id || event.srcElement.attributes.id || event.currentTarget.attributes.id;
		let value = target.nodeValue;
		console.log(value);
		
		// For Dialog Configuration
		const dialogConfig = new MatDialogConfig();

		dialogConfig.autoFocus = true;
		dialogConfig.data = {
			details: this.fileData[value - 1],
			category: this.slug,
			id: value
		}

		const dialogProduct = this.dialog.open(ModifyProductComponent, dialogConfig)
	}

	deleteProduct(event) {
		let target = event.target.attributes.id || event.srcElement.attributes.id || event.currentTarget.attributes.id;
		let value = target.nodeValue;
		console.log(value);
		
		// For Dialog Configuration
		const dialogConfig = new MatDialogConfig();

		dialogConfig.autoFocus = true;
		dialogConfig.data = {
			category: this.slug,
			name: this.fileData[value - 1].product_name,
			id: value
		}

		const dialogProduct = this.dialog.open(DeleteProductComponent, dialogConfig)
	}

	applyFilter() {
		this.dataSource.filter = this.query.trim().toLowerCase();
		if(this.dataSource.filteredData.length > 0) {
			this.doesTableHaveData = true;
		}
		else
		{
			this.doesTableHaveData = false;
		}
	}

	onClear()
	{
		this.query = "";
		this.applyFilter();
	}

	logout() {
		localStorage.removeItem("key")
		this.snackbar.open('Successfully Logged Out', 'Dismiss', {
			duration: 3500
		});
		this.router.navigate(['/products/categories/aarsh/remedies/pvt/ltd'])
	}
}


/* AddProduct Component */
@Component({
	selector: 'add-product',
	templateUrl: './addProduct.html',
	styleUrls: ['./addProduct.css']
})

export class addProductComponent implements OnInit {
	form: FormGroup
	catValue: string
	catName: string

	constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData, private fb: FormBuilder, public dialogRef: MatDialogRef<addProductComponent>, private _admin: AdminService, private snackbar: MatSnackBar){
		this.catValue = data.catValue
		this.catName = data.catName
	}

	
	ngOnInit(): void {
		this.form = this.fb.group({
			productName: '',
			productCategory: {
				value: this.catValue,
				disabled: true
			},
			productFormulation: '',
			packing: ''
		})
	}

	onSubmit() {
		var data = {
			"productCategory" : this.catValue,
			"productName": this.form.value.productName,
			"productFormulation": this.form.value.productFormulation,
			"packing": this.form.value.packing
		}

		this._admin.addProduct(data).pipe(take(1)).subscribe(
			err => {
				this.dialogRef.close();
				this.snackbar.open("Add Product Cancelled", "Dismiss", {
					duration: 3500,
				});
			},
			res => {
				console.log("In res");

				this.dialogRef.close();
				var msg = ""
				
				if(res.status === 204) {
					msg = 'Data Empty, not modified'
				}
				if(res.status === 304) {
					msg = "Couldn't read file. Please try again"
					
				}
				if(res.status === 202) {
					msg = 'Product Added Successfully'
				}
				
				this.snackbar.open(msg, "Dismiss", {
					duration: 3500,
				});
			}
		)
	}

	close()
	{
		this.dialogRef.close();
	}

}


/* ModifyProduct Component */
@Component({
	selector: 'modify-product',
	templateUrl: './modifyProduct.html',
	styleUrls: ['./modifyProduct.css']
})

export class ModifyProductComponent implements OnInit {
	form: FormGroup

	category: string;
	productName: string
	productFormulation: string
	packing: string
	id: number

	constructor(@Inject(MAT_DIALOG_DATA) public data: ModifyDialogData, private fb: FormBuilder, public dialogRef: MatDialogRef<ModifyProductComponent>, private _admin: AdminService, private snackbar: MatSnackBar){
		this.category = data.category
		this.productName = data.details.	product_name
		this.productFormulation = data.details.product_formulation
		this.packing = data.details.packing
		this.id = data.id
	}

	
	ngOnInit(): void {
		this.form = this.fb.group({
			productCategory : this.category,
			productName: this.productName,
			productFormulation: this.productFormulation,
			packing: this.packing
		})
	}

	to_upper()
	{
		// this.form = this.fb.group({
		// 	productCategory : this.category,
		// 	productName: this.productName,
		// 	productFormulation: this.productFormulation,
		// 	packing: this.packing
		// })
		return this.productFormulation.toUpperCase();
		// this.form.productFormulation = this.productFormulation.toUpperCase();
	}
	
	to_lower()
	{
		return this.productFormulation.toLowerCase();
	}
	
	to_title()
	{
		return this.productFormulation.split(' ').map(w => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(' ');
	}

	onSubmit() {

		if(this.productName === this.form.value.productName && this.productFormulation === this.form.value.productFormulation && this.packing === this.form.value.packing) {
			this.dialogRef.close();
			this.snackbar.open("No changes made", "Dismiss", {
				duration: 3500
			})
		}
		else {
			var data = {
				"productCategory" : this.category,
				"productName": this.form.value.productName,
				"productFormulation": this.form.value.productFormulation,
				"packing": this.form.value.packing,
				"id": this.id
			}
			
			this._admin.modifyProduct(data).pipe(take(1)).subscribe(
				err => {
					this.dialogRef.close();
					this.snackbar.open("Modify Product Cancelled", "Dismiss", {
						duration: 3500,
					});
				},
				res => {
					console.log("In res");
					
					this.dialogRef.close();
					var msg = ""
					
					if(res.status === 204) {
						msg = 'Data Empty, not modified'
					}
					if(res.status === 304) {
						msg = "Couldn't read file. Please try again"
						
					}
					if(res.status === 202) {
						msg = 'Product Modified Successfully'
					}
					
					this.snackbar.open(msg, "Dismiss", {
						duration: 3500,
					});
				}
			)
			
		}
	}

	close() {
		this.dialogRef.close();
	}
}


/* DeleteProduct Component */
@Component({
	selector: 'delete-product',
	templateUrl: './deleteProduct.html',
	styleUrls: ['./deleteProduct.css']
})

export class DeleteProductComponent implements OnInit {

	category: string
	name: string
	id: number

	constructor(@Inject(MAT_DIALOG_DATA) public data: DeleteDialogData, public dialogRef: MatDialogRef<DeleteProductComponent>, private _admin: AdminService, private snackbar: MatSnackBar){
		this.category = data.category
		this.name = data.name
		this.id = data.id
	}

	
	ngOnInit(): void {}

	onSubmit(event) {
		let target = event.target.attributes.id || event.srcElement.attributes.id || event.currentTarget.attributes.id;
		let value = target.nodeValue;
		
		if(value === 'delete') {
			var data = {
				productCategory : this.category,
				id: this.id
			}
			
			this._admin.deleteProduct(data).pipe(take(1)).subscribe(
				err => {
					this.dialogRef.close();
					this.snackbar.open("Delete Product Cancelled", "Dismiss", {
						duration: 3500,
					});
				},
				res => {
					this.dialogRef.close();
					var msg = ""
					
					if(res.status === 204) {
						msg = 'Data Empty, not modified'
					}
					if(res.status === 304) {
						msg = "Couldn't read file. Please try again"
					}
					if(res.status === 202) {
						msg = 'Product Deleted Successfully'
					}
					
					this.snackbar.open(msg, "Dismiss", {
						duration: 3500,
					});
				}
			)
		}
	}

	close(event) {
		this.dialogRef.close();
	}
}


export interface DialogData {
	catName: string
	catValue: string
}

export interface ModifyDialogData {
	details: {
		product_name: string,
		product_formulation: string,
		packing: string
	}
	category: string
	id: number
}

export interface DeleteDialogData {
	category: string
	name: string
	id: number
}
