import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { GlobalConstants } from "../common/global-constants";

@Injectable({
	providedIn: 'root'
})
export class ContactService {

	constructor(private httpClient: HttpClient) { }

	basePath = GlobalConstants.apiURL

	private _cf = this.basePath + "contactForm";

	public contactForm(formData): Observable<any> {
		return this.httpClient.post<any>(this._cf, formData);
	}
}
