<div class="center container">
	<br><br>
	<div class="header">
		<div class="container">
			<span style="float: left;">
				<button 
					class="fonts"
					mat-icon-button
					[routerLink]="['/admin-dashboard']"
					aria-label="Back"
					matTooltip="Back to Dashboard">
					<mat-icon>arrow_back</mat-icon>
				</button>
			</span>
			<h1>Advanced Search</h1>
		</div>
	</div>
	<div>
		<h2 class="center">Search Within All Products of All Categories</h2>
	</div>
</div>
<div class="container center">

	<!-- Search Bar Above Table -->
		<mat-form-field>
			<!-- <mat-icon matPrefix>search</mat-icon> -->
			<mat-label>Search</mat-label>
			<input matInput [(ngModel)]="query" placeholder="Type to Start Searching..." (keyup)="applyFilter()" autofocus>
			<button mat-icon-button matSuffix aria-label="Clear" (click)="onClear()" *ngIf="query" aria-label="Clear" matTooltip="Clear Input">
				<mat-icon>close</mat-icon>
			</button>
		</mat-form-field>
		<!-- <button mat-raised-button (click)="applyFilter()" color="primary">
			<mat-icon>search</mat-icon>
			Search
		</button> -->
	
	<!-- Table -->
	<div>
		<table mat-table [dataSource]="dataSource" appMaterialElevation>

			<!-- No. Column -->
			<ng-container matColumnDef="no">
				<th mat-header-cell *matHeaderCellDef>No.</th>
				<td mat-cell *matCellDef="let i = index;">{{ i + 1 }}</td>
			</ng-container>

			<!-- Product Name Column -->
			<ng-container matColumnDef="product_name">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Product Name</th>
				<td mat-cell *matCellDef="let element">
					{{ element.product_name }}
				</td>
			</ng-container>

			<!-- Product Formulation Column -->
			<ng-container matColumnDef="product_formulation">
				<th mat-header-cell *matHeaderCellDef mat-sort-header>Product Formulation</th>
				<td mat-cell *matCellDef="let element;">{{ element.product_formulation }}</td>
			</ng-container>
			
			<!-- Name Column -->
			<ng-container matColumnDef="displayName">
				<th mat-header-cell *matHeaderCellDef>Category</th>
				<td mat-cell *matCellDef="let element">
					<a fxFlexFill [routerLink]="'/admin-dashboard/category/' + element.name" class="mat-row-link">{{ element.displayName }}</a>
				</td>
			</ng-container>
			
			<!-- Launch Icon -->
			<ng-container matColumnDef="launch">
				<th mat-header-cell *matHeaderCellDef>Open</th>
				<td mat-cell *matCellDef="let element;">
					<button 
						mat-icon-button 
						color="primary" 
						matTooltip="Open"
						[routerLink]="'/admin-dashboard/category/' + element.name">
						<mat-icon>launch</mat-icon>
					</button>
				</td>
			</ng-container>

			<ng-template [ngIf]="dataSource?.data.length === 0">
				<tr mat-footer-row *matFooterRowDef="['noRecord']"></tr>
			</ng-template>

			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
		</table>
	</div>
</div>

<scroll-to-top></scroll-to-top>

<br>