import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule, routingComponents } from './app-routing.module';
import { AppComponent } from './app.component';

/* For Custom Icons, REST API, Interceptor */
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";

/* Navbar and Footer */
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { ScrollToTopComponent } from './scroll-to-top/scroll-to-top.component';

/* Material Import */
import { MaterialModule } from "./material/material.module";
import { FlexLayoutModule } from "@angular/flex-layout";

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';

/* ParticleJS Import */
import { NgParticlesModule } from "ng-particles";

/* Image Slider Import */
// https://www.npmjs.com/package/ng-image-slider
import { NgImageSliderModule } from 'ng-image-slider';

/* Material Carousel Import */
import { IvyCarouselModule } from 'angular-responsive-carousel';

/* Timeline Import */
// https://openbase.com/js/ngx-timeline-albe
import { NgxTimelineAlbeModule } from 'ngx-timeline-albe';

/* Material International Phone Input Import */
// https://openbase.com/js/ngx-mat-intl-tel-input
import { NgxMatIntlTelInputModule } from "ngx-mat-intl-tel-input";

/* Directives */
import { MaterialElevationDirective } from "./material/material-elevation.directive";
import { ScrollTracker } from './directives/ScrollTracker.directive';

/* For Dialogues */
import { addProductComponent, ModifyProductComponent, DeleteProductComponent } from "./admin-functionalities/admin-functionalities.component";
import { AddCategoryComponent, ModifyCategoryComponent, DeleteCategoryComponent } from "./admin-dashboard/admin-dashboard.component";
import { ContactDialog, FormResponse } from "./contact/contact.component";
import { VerifyPWD } from "./admin-settings/admin-settings.component";
import { VerifyOTP } from './admin/admin.component';

/* For Authentication */
import { AuthGuard } from './guard/auth.guard';
import { TokenInterceptorService } from './services/token-interceptor.service';

@NgModule({
	declarations: [
		AppComponent,

		NavbarComponent,
		FooterComponent,
		ScrollToTopComponent,
		routingComponents,

		/* Directives */
		MaterialElevationDirective,
		ScrollTracker,

		/* For Dialogs */
		ContactDialog,
		FormResponse,
		VerifyOTP,
		VerifyPWD,

		/* Admin */
		AddCategoryComponent,
		ModifyCategoryComponent,
		DeleteCategoryComponent,

		addProductComponent,
		ModifyProductComponent,
		DeleteProductComponent,

		/* Form */
		// ContactForm,

		ScrollToTopComponent,
	],
	imports: [
		BrowserAnimationsModule,
		ReactiveFormsModule,
		AppRoutingModule,
		BrowserModule,
		FormsModule,

		HttpClientModule,

		/* Material Import */
		MaterialModule,
		FlexLayoutModule,

		NgParticlesModule,

		IvyCarouselModule,
		NgImageSliderModule,
		
		NgxTimelineAlbeModule,
		NgxMatIntlTelInputModule,
	],
	providers: [
		Title,
		AuthGuard,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: TokenInterceptorService,
			multi: true
		},
		MatDatepickerModule,
		MatNativeDateModule,
	],
	bootstrap: [ AppComponent ]
})
export class AppModule { }
