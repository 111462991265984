<footer>
	<section class="section-top">
		<div class="col">
			<a [routerLink]="['/']" routerLinkActive="router-link-active">
				<img src="assets/files/logo.png" alt="Aarsh Remedies" class="img">
			</a>
			<div>
				<h3>Aarsh Remedies Pvt. Ltd.</h3>
			</div>
		</div>
		
		<div class="col">
			<h1>Quick Links</h1>
			<div class="links"><a routerLink="/">Home</a></div>
			<div class="links"><a routerLink="/about-us">About</a></div>
			<div class="links"><a routerLink="/products">Products</a></div>
		</div>
	
		<div class="col">
			<h1>Products</h1>
			<div class="links"><a routerLink="/products/antibiotics">Antibiotics</a></div>
			<div class="links"><a routerLink="/products/injectables">Injectables</a></div>
			<div class="links"><a routerLink="/products/cardiac-anti-diabetic">Cardiac & Anti-Diabetic</a></div>
			<div class="links"><a routerLink="/products/dental">Dental</a></div>
			<div class="links"><a routerLink="/products/">All Products</a></div>
		</div>
		
		<div class="col">
				<h1>Contact</h1>
				<div>
					<button mat-button (click)="tel()">
						<mat-icon>call</mat-icon>
						+91-99798 96925
					</button>
					<br>
					<button mat-button (click)="whatsapp()">
						<mat-icon svgIcon="whatsapp"></mat-icon>
						+91-99798 96925
					</button>
					<br>
					<button mat-button (click)="primary()">
						<mat-icon fontSet="material-icons-outlined">mail</mat-icon>
						aarshremedies@gmail.com
					</button>
					<br>
					<button mat-button (click)="secondary()">
						<mat-icon>mail</mat-icon>
						brijsheth@aarshremedies.com
					</button>
					<br>
					<button mat-button (click)="navigate()" matTooltip="Location">
						<mat-icon>place</mat-icon>
						Signature 2, S.G. Highway,
						<div fxHide.gt-lg fxHide.lt-sm>
						</div>
						Ahmedabad, Gujarat, India
					</button>
				</div>
		</div>

	</section>
	
	<mat-toolbar>
		<mat-toolbar-row  fxLayout="row wrap" fxLayoutAlign="space-around center">
			<div class="center">
				&copy; 2010 - {{ currentYear }} <a href="{{ siteURL }}"><b>Aarsh Remedies Pvt. Ltd.</b><sup>TM</sup></a>
			</div>
			<div class="center">
				Designed & Developed By: <a target="_blank" href="https://www.linkedin.com/in/aasheth/"><b>Aarsh Sheth</b></a>
			</div>
		</mat-toolbar-row>
	</mat-toolbar>

</footer>
