<form [formGroup]="form" (ngSubmit)="onVerify()">
	<div class="content">
		<h2 mat-dialog-title>Verify Password</h2>
		<mat-dialog-content>
			<h2>Please Verify the Password to Continue</h2>
			<br>
			<mat-form-field>
				<mat-label>Password</mat-label>
				<input matInput id="pwd" [type]="hide ? 'password' : 'text'" placeholder="Password" formControlName="password" required #pwd>

				<mat-icon matSuffix (click)="hide = !hide" class="eyeIcon">
					{{ hide ? 'visibility_off' : 'visibility' }}
				</mat-icon>
				
				<mat-error>
					<b>Password</b> Required
				</mat-error>

				<mat-hint align="end"><b>{{ pwd.value.length }}</b></mat-hint>
			</mat-form-field>
			<br><br>
		</mat-dialog-content>

		<mat-dialog-actions align="end">
			<button mat-raised-button color="primary" type="submit" [disabled]="!form.valid">Verify</button>
			<button mat-raised-button color="warn" (click)="cancel()">Cancel</button>
		</mat-dialog-actions>
	</div>
</form>