<div class="main__container">
	<div class="header">
		<h1>About Us</h1>
	</div>
</div>
<div class="container">
	<main>
		<ngx-timeline 
			[itens]="data"
			[sortDesc]="false"
		>
		</ngx-timeline>
		<mat-card>
			<mat-card-header>
				<mat-card-title class="card-title">
					<h1>Driven By Our Promise</h1>
				</mat-card-title>
			</mat-card-header>
			<mat-card-content>
				<p>
					It began with a promise. Aarsh Remedies’s parent company, Dial Pharmaceuticals, was formed more than 30 years ago to save lives using the latest technologies. Since then, Aarsh Remedies Pvt. Ltd. has grown into a Pharmaceutical leader, driven by that same promise to save and improve lives. We offer the broadest range of quality finished formulation products in our industry. Our ability to innovate and deliver life-saving medicines for patients has earned us a reputation for always putting patients first. We are Aarsh Remedies Pvt. Ltd. and we are driven by our promise.
				</p>
			</mat-card-content>
		</mat-card>
		<br>

		<mat-card>
			<mat-card-header>
				<mat-card-title class="card-title"><h1>Our Story</h1></mat-card-title>
			</mat-card-header>
			<mat-card-content>
				<p>
					Aarsh Remedies Pvt. Ltd. was established in 2010 to service the health needs of the nation. Since then, Aarsh Remedies Pvt. Ltd. has grown into a leader in providing quality finished product formulations in the country. Aarsh Remedies’ strong commitment to quality has helped the company to make its presence all across the globe. Today, Aarsh Remedies Pvt. Ltd. offers services in 20+ countries worldwide including countries in different regions like Asia, Europe, North America, Latin America, Africa, and Australia. Today, out of a broad range of product portfolio of 400+ products, Aarsh Remedies Pvt. Ltd. stands leader in 15+ brands in the country.
				</p>
			</mat-card-content>
		</mat-card>
		<br>
	</main>
</div>

<mat-grid-list cols="8" rowHeight="120px">
	<mat-grid-tile
		*ngFor="let tile of tiles"
		[colspan]="tile.cols"
		[rowspan]="tile.rows"
	>
		<div *ngIf="tile.image" class="grid-image">
			<img src="{{ tile.image }}" alt="" height="362px">
		</div>

		<div *ngIf="tile.header">
			<div class="center">
				<h1>{{ tile.header }}</h1>
			</div>
			<div class="container-fl">
				<h3>
					<pre>{{ tile.text }}</pre>
				</h3>
			</div>
		</div>

	</mat-grid-tile>
</mat-grid-list>

<scroll-to-top></scroll-to-top>

<br>
