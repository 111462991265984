import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl } from '@angular/forms';
import { COMMA, ENTER, TAB } from '@angular/cdk/keycodes';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';

import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatSnackBar } from '@angular/material/snack-bar';

import { AdminService } from '../services/admin.service';

@Component({
	selector: 'app-admin-settings',
	templateUrl: './admin-settings.component.html',
	styleUrls: ['./admin-settings.component.css']
})

export class AdminSettingsComponent implements OnInit {

	hide = true;
	info: any;

	adminEmail: string;
	adminPassword: string;
	loginOTPEmail: string;

	mail_receipents: {};
	mailReceipents: [] = [];

	credForm: FormGroup;
	formData: {};

	constructor(private title: Title, private snackbar: MatSnackBar, public dialog: MatDialog, private _admin: AdminService, private fb: FormBuilder) {

		this.title.setTitle("Settings | Admin | Aarsh Remedies Pvt. Ltd.");

		this._admin.readCredentials().pipe(take(1)).subscribe(
			res => {
				this.adminEmail = res.admin.credentials.login_email;
				this.adminPassword = res.admin.credentials.login_password;
				this.loginOTPEmail = res.admin.login_otp_email;
			}
		)

		this.info = this._admin.getContactQueryEmail().pipe(take(1)).subscribe(
			res => {
				this.mailReceipents = res.contact_query.receipents;
			}
		)
		this.credForm = this.fb.group({
			email: [this.adminEmail, [Validators.required, Validators.email]],
			password: [this.adminPassword, [Validators.required]],
		})
	}

	ngOnInit(): void {

		// For Dialog Configuration
		const dialogConfig = new MatDialogConfig();

		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;
		// const dialogRef = this.dialog.open(VerifyPWD, dialogConfig)

	}

	onCredentialsChange() {
		console.log(this.credForm.value.email);
		console.log(this.credForm.value.password);
		this.formData = {
			new_email: this.credForm.value.email,
			new_password: this.credForm.value.password
		};
		console.log(this.formData);
		

		this._admin.changeContactQueryEmail(this.formData).pipe(take(1)).subscribe(
			err => {
				this.snackbar.open('Error Updating Configuration, please try again', 'Dismiss', {
					duration: 5000
				});
			},
			res => {
				if (res.status === 202) {
					this.snackbar.open('Settings Updated Successfully', 'Dismiss', {
						duration: 0
					});
					// console.log("snackbar opened in success");
				}
				else if (res.status === 304){
					this.snackbar.open('File Not Modified', 'Dismiss', {
						duration: 5000
					});
				}
				else {
					this.snackbar.open('Error updating configuration, please try again', 'Dismiss', {
						duration: 5000
					});
				}
				// this.isDisabled = false;
			}
		)
	}

	visible = true;
	selectable = false;
	removable = true;

	/* Contact Query Settings */
	
	public readonly separatorKeysCodes: number[] = [ENTER, COMMA, TAB];
	
	public emailFormControl: FormControl = new FormControl(
		[],
		Validators.required
	);
	
	public queryForm: FormGroup = new FormGroup({
		email: new FormControl(this.mailReceipents, Validators.required),
		emailHelper: new FormControl(null, Validators.email)
	});
	
	// Add Email
	public addEmailAddress(event: MatChipInputEvent): void {
		const formControl: AbstractControl = this.queryForm.get("email");
		const helperForm: AbstractControl = this.queryForm.get("emailHelper");
		const input: HTMLInputElement = event.input;
		const value: string = (event.value || "").trim();

		helperForm.updateValueAndValidity();

		if (helperForm.valid) {
			if (value) {
				formControl.setValue([...formControl.value, value]);
			}

			formControl.updateValueAndValidity();

			if (input) {
				input.value = "";
			}
		} else {
			formControl.setErrors({ email: true });
		}
	}

	// Remove Email
	public removeEmailAddress(selectedEmail: string): void {
		const formControl: AbstractControl = this.queryForm.get("email");
		const value: string[] = formControl.value.filter(
			(email: string) => email !== selectedEmail
		);
		formControl.setValue(value);
		formControl.updateValueAndValidity();
	}

	onContactQuerySubmit() {
		console.log(this.queryForm.value.email);
		this.mail_receipents = {
			all_emails: this.queryForm.value.email
		};
		console.log(this.mail_receipents);
		

		this._admin.changeContactQueryEmail(this.mail_receipents).pipe(take(1)).subscribe(
			err => {
				this.snackbar.open('Error Updating Configuration, please try again', 'Dismiss', {
					duration: 5000
				});
			},
			res => {
				if (res.status === 202) {
					this.snackbar.open('Settings Updated Successfully', 'Dismiss', {
						duration: 0
					});
					// console.log("snackbar opened in success");
				}
				else if (res.status === 304){
					this.snackbar.open('File Not Modified', 'Dismiss', {
						duration: 5000
					});
				}
				else {
					this.snackbar.open('Error updating configuration, please try again', 'Dismiss', {
						duration: 5000
					});
				}
				// this.isDisabled = false;
			}
		)
	}

}

/* VerifyPWD Component */
@Component({
	selector: 'verifyPWD',
	templateUrl: 'verifyPWD.html',
	styleUrls: ['verifyPWD.css']
})

export class VerifyPWD implements OnInit {
	form: FormGroup;

	pwd: string;
	hide: boolean = true;

	loginAttempts: number;

	constructor(public dialogRef: MatDialogRef<VerifyPWD>, @Inject(MAT_DIALOG_DATA) public data: DialogData, private snackbar: MatSnackBar, private _admin: AdminService, private fb: FormBuilder, private router: Router, public dialog: MatDialog,) {
		this.loginAttempts = 3;
	}

	ngOnInit(): void {
		this.form = this.fb.group({
			password: ''
		})
	}

	cancel() {
		this.form.reset();
		this.router.navigate(['/admin-dashboard'])
		this.dialogRef.close();
		this.snackbar.open('Password Verification Cancelled', 'Dismiss', {
			duration: 3500
		});
	}

	onVerify() {
		this.pwd = this.form.value.password;

		if (this.pwd !== '' || this.pwd !== null) {
			this._admin.verifyPassword(this.pwd).pipe(take(1)).subscribe(
				error => {
					console.log("Not Authorized");
					// this.snackbar.open('Password Verification Cancelled', 'Dismiss', {
					// 	duration: 3500,			
					// });
				},
				res => {
					if (res.status === 200) {
						console.log("Authorized")
						this.dialogRef.close()
						this.snackbar.open('Password Verified', 'Dismiss', {
							duration: 3500
						});
					}
					if (res.status === 401) {

						if (this.loginAttempts === 0) {
							this.router.navigate(['/admin-dashboard'])
							this.dialogRef.close();
							this.snackbar.open("3 Failed Attempts. Please Try Again.", 'Dismiss', {
								duration: 3500
							})
						}
						else {
							this.snackbar.open("Incorrect Password - " + this.loginAttempts + " Attempt(s) remaining", 'Dismiss', {
								duration: 3500
							})

							this.loginAttempts--;
						}
						// this.snackbar.open('Incorrect Credentials', 'Dismiss', {
						// 	duration: 3500
						// });
					}
				}
			);
		}
	}
}

export interface DialogData {
	password: string
}