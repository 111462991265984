<div class="scroll-to-top" [ngClass]="{'show-scrollTop': windowScrolled}">
	<button 
		mat-fab
		(click)="scrollToTop()"
		color="primary"
		matTooltip="Back to Top"
		aria-label="Back to Top">
		<mat-icon>expand_less</mat-icon>
	</button>
</div>
