import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { ProductsComponent } from './products/products.component';
import { ProductDetailComponent } from "./product-detail/product-detail.component";

import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

import { AdminComponent } from './admin/admin.component';
import { AdminSearchComponent } from './admin-search/admin-search.component';
import { AdminSettingsComponent } from './admin-settings/admin-settings.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { AdminFunctionalitiesComponent } from './admin-functionalities/admin-functionalities.component';

import { AuthGuard } from './guard/auth.guard';

const routes: Routes = [

	{
		path: 'products/categories/aarsh/remedies/pvt/ltd',
		component: AdminComponent
	},
	{ 
		path : 'admin-dashboard/search',
		component : AdminSearchComponent,
		canActivate: [ AuthGuard ]
	},
	{ 
		path : 'admin-dashboard/settings',
		component : AdminSettingsComponent,
		canActivate: [ AuthGuard ]
	},
	{ 
		path : 'admin-dashboard',
		component : AdminDashboardComponent,
		canActivate: [ AuthGuard ]
	},
	{ 
		path : 'admin-dashboard/category/:slug',
		component : AdminFunctionalitiesComponent,
		canActivate: [ AuthGuard ]
	},
	{
		path : 'about-us',
		component : AboutComponent
	},
	{
		path : 'contact-us',
		component : ContactComponent
	},
	{
		path : 'products',
		component : ProductsComponent
	},
	{
		path : 'products/:slug',
		component : ProductDetailComponent
	},
	{
		path : '',
		component : HomeComponent
	},
	{
		path : '404',
		component : PageNotFoundComponent
	},
	{
		path : '**',
		component : PageNotFoundComponent
	}
];

@NgModule({
	imports: [ RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', onSameUrlNavigation: 'reload' }) ],
	exports: [ RouterModule ]
})

export class AppRoutingModule { }

export const routingComponents = [
	HomeComponent,
	AboutComponent,
	ContactComponent,
	ProductsComponent,
	ProductDetailComponent,
	PageNotFoundComponent,
	
	AdminComponent,
	AdminSearchComponent,
	AdminSettingsComponent,
	AdminDashboardComponent,
	AdminFunctionalitiesComponent,
]
