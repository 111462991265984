import { Component, OnInit } from '@angular/core';
import { GlobalConstants } from '../common/global-constants';
@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

	currentYear = new Date().getFullYear();
	siteURL: string = GlobalConstants.siteURL;
	constructor() { }

	ngOnInit(): void {
	}

	contact() {
		location.href = "/contact-us";
	}
	
	tel() {
		location.href = "tel:+919979896925";
	}
	
	whatsapp() {
		location.href = "https://wa.me/919979896925";
	}

	telegram() {
		location.href = "https://t.me/aarshremedies";
	}
	
	signal() {
		location.href = "https://signal.me/aarshremedies";
	}
	
	primary() {
		location.href = `mailto:${GlobalConstants.primary_email}`;
	}
	
	secondary() {
		location.href = `mailto:${GlobalConstants.secondary_email}`;
	}
	
	navigate() {
		window.open("https://goo.gl/maps/efvj4YzLU9zV7MAi8");
	}
	
}
