import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

import { ReadJSONService } from "../services/read-json.service";
import { ImageService } from "../services/image.service";

@Component({
	selector: 'app-product-detail',
	templateUrl: './product-detail.component.html',
	styleUrls: ['./product-detail.component.css']
})

export class ProductDetailComponent implements OnInit, OnDestroy
{
	doesTableHaveData: boolean = true;
	private subRoute: any;
	slug: string;
	catName: string;
	
	query: string;
	len: number;

	tableData: boolean = false;
	
	// Table Options
	dataSource: any;
	displayedColumns: string[] = ["no", "product_name", "product_formulation", "packing"];
	pageSizeOption = [50, 100];

	_images: Array<any> = [];

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;
	
	public constructor(private title : Title, private route: ActivatedRoute, private router: Router, private readJSON: ReadJSONService)
	{	
		// To get Parameters
		this.subRoute = this.route.params.subscribe(params => {
			this.slug = params['slug'];
			
			this.readJSON.getJSON(this.slug).subscribe(
				data => {					
					this.catName = data["details"]["displayName"];

					/* To set Title */
					this.title.setTitle(this.catName + " | Aarsh Remedies Pvt. Ltd.");
					this.len = data["products"].length;
					
					if (this.len === 0) {
						this.tableData = false;
					} else {
						this.tableData = true;
					}

					this.dataSource = new MatTableDataSource(data["products"]);

					/* For Sorting & Pagination in table */
					this.dataSource.sort = this.sort;
					this.dataSource.paginator = this.paginator;
				}, 
				(error) => {
					this.router.navigate(['/404']);
				}
			);
		})
	}
	
	ngOnInit(): void {
	}

	ngOnDestroy() {
		this.subRoute.unsubscribe();
	}

	applyFilter() {
		this.dataSource.filter = this.query.trim().toLowerCase();
		if(this.dataSource.filteredData.length > 0) {
			this.doesTableHaveData = true;
		}
		else {
			this.doesTableHaveData = false;
		}
	}

	onClear() {
		this.query = "";
		this.applyFilter();
	}
}
