<div class="main__container">
	<br><br><br>
	<div class="svg">
		<!-- <object type="image/svg+xml" data="assets/svg/login.svg"  class="svg"> -->
			<img src="assets/svg/two_factor_authentication.svg" alt="Login SVG">
		<!-- </object> -->
	</div>
	<div class="header">
		<h1>Admin</h1>
	</div>
</div>
<div class="center">
	<div class="container center">
		<mat-card appMaterialElevation>
			<mat-card-header>
				<mat-card-title class="center">
					<h2>Login</h2>
				</mat-card-title>
			</mat-card-header>
			<mat-card-content>
				<form [formGroup]="form" (ngSubmit)="onSubmit()">
					<div class="form-input">
						<mat-form-field class="form-input">
							<input matInput placeholder="Email" id="email" name="email" formControlName="email" required>

							<mat-error>
								Please Enter a <b>Valid Email Address</b>
							</mat-error>
						</mat-form-field>
						<br>
						<mat-form-field class="form-input">
							<input matInput [type]="hide ? 'password' : 'text'" placeholder="Password" id="password" name="password" formControlName="password" required #pwd>

							<mat-icon matSuffix (click)="hide = !hide" class="eyeIcon">{{ hide ? 'visibility_off' : 'visibility' }}
							</mat-icon>
							
							<mat-hint align="end"><b>{{ pwd.value.length }} / 16</b></mat-hint>

							<mat-error>
								<b>Password</b> Required
							</mat-error>
						</mat-form-field>
					</div>
					<br>
					<div class="center">
						<button mat-raised-button color="primary" type="submit" [disabled]="!form.valid">
							<mat-icon>admin_panel_settings</mat-icon>
							Login
						</button>
					</div>
				</form>
			</mat-card-content>
		</mat-card>
	</div>
</div>
<br><br><br><br><br><br>